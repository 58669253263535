import { notification } from "./customToast";

export default {
  data() {
    return {

      page_title: ''

    };
  },
  mounted() {
    document.title = this.page_title;
  },

  methods: {
    clicked(value) {
      this.$swal('Heading', value, 'OK');
    },
    errorHandle(response) {
      console.log(response);
      if (response.status == 401) {
        notification("Ocorreu um erro ao alterar a interação!", 'error')
        localStorage.clear();
        this.$router.push("/login");
        return false
      } else if (response && response.data && response.data.error) {
        if(typeof response.data.error === "object") {
          notification(response.data.message + "\n" + Object.keys(response.data.error), 'error')
        } else {
          notification(response.data.message + "\n" + response.data.error, 'error')
        }
      } else {
        if(response.data.message){
          notification(response.data.message)  
        }else{
          notification("Ocorreu um erro ao alterar a interação!", 'error')
        }
      }

    },
    isValidLogin() {
      if (localStorage.getItem('token') == null) {
        notification("Token expire", "error")
        this.$router.push("/login");
      }
    }
  }
};