<template>
  <header class="mainheader py-3">
    <b-container>
      <b-row>
        <b-col md="5" xl="3" class="d-flex col-7 align-items-center">
          <a href="/login"
            ><img
              alt="logo"
              class="mainlogo"
              src="../assets/images/cemig-logo.svg"
          /></a>
          <span class="slogan"> Seu médico sem sair de casa </span>
        </b-col>
        <b-col
          md="7"
          xl="9"
          class="d-flex align-items-center col-5 justify-content-end mt-1"
        >
          <template
            v-if="
              this.$route.name == 'LoginScreen' || this.$route.name == 'ForgetPassword' ||
              this.$route.name == 'ResetPassword' || this.$route.name == 'HOMEROUTE'
            "
          >
            <h6 class="headline m-0 w-100 text-center">
              Cadastro e relatórios de novos usuários
            </h6>
          </template>

          <b-navbar toggleable="lg" class="navlinks py-0" type="dark">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <!-- <h3> Cadastro e relatórios de novos usuários </h3> -->

            <b-collapse
              id="nav-collapse"
              is-nav
              v-if="
                this.$route.name != 'LoginScreen' &&
                this.$route.name != 'ResetPassword' && 
                this.$route.name != 'ForgetPassword' && this.$route.name != 'HOMEROUTE'
              "
            >
              <b-navbar-nav>
                <b-nav-item to="/home">
                  <img src="../assets/images/groupicon.svg" alt="" />
                  Cadastro de usuários</b-nav-item
                >
                <b-nav-item to="/report">
                  <img src="../assets/images/docicon.svg" alt="" />
                  Relatórios</b-nav-item
                >
                <b-nav-item href="#">
                  <img
                    v-if="profile == ''"
                    src="../assets/images/profile.svg"
                    alt=""
                  />
                  <img
                    v-else
                    :src="profile"
                    width="50px"
                    height="50px"
                    class="circleimage"
                    ref="profile"
                    mr-0
                  />
                </b-nav-item>
                <b-nav-item @click="logOut()">Sair</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav v-if="records.length > 0">
                <b-nav-item-dropdown text="Download" right>
                  <b-dropdown-item
                    v-for="rec in records"
                    :key="rec._id"
                    @click="updateStatus(rec)"
                    class="nav-dropdown"
                    >{{ rec.fileName }}</b-dropdown-item
                  >
                  <!-- <b-dropdown-item href="#">EN</b-dropdown-item>
                  <b-dropdown-item href="#">ES</b-dropdown-item>
                  <b-dropdown-item href="#">RU</b-dropdown-item>
                  <b-dropdown-item href="#">FA</b-dropdown-item> -->
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import Vue from "vue";
let self;
import axios from "axios";
import commonMixin from '../utils/commonMixin.js'

export default Vue.extend({
  name: "Header",
  mixins:[commonMixin],
  components: {},
  props: ["records"],
  data() {
    return {
      profile: "",
      userData: {},
      getLoad: false,
    };
  },
  updated() {
    if (
      this.$route.name != "LoginScreen" &&
      this.$route.name != "ResetPassword" && 
      this.$route.name != 'ForgetPassword' && this.$route.name != "HOMEROUTE"
    ) {
      let userData = JSON.parse(localStorage.getItem("userObj"));
      this.profile = userData.avatar;
    }
  },
  mounted() {
    console.log(this.records);
    self = this;
    if (
      this.$route.name != "LoginScreen" &&
      this.$route.name != "ResetPassword" && 
      this.$route.name != 'ForgetPassword' && this.$route.name != "HOMEROUTE"
    ) {
      let userData = JSON.parse(localStorage.getItem("userObj"));
      this.userData = userData;
      this.profile = userData.avatar;
    }
  },

  methods: {
    async updateStatus(data) {
      try {
        var fileURL = data.params;
        window.open(fileURL, "_blank");
        this.getLoad = true;
        let result = await axios.get(
          process.env.VUE_APP_API_URL +
            `/report/update-complete-report-status/${data._id}`
        );
        console.log(result);
        if (result.status == 200) {
          this.getLoad = false;
         this.$emit('getfiltes')
          // this.reset();
        } else {
          this.getLoad = false;
        
        }
      } catch (e) {
        this.errorHandle(e.response);
        this.getLoad = false;
        console.log(e);
      }
    },
    //   async getReports() {
    //   try {

    //     this.getLoad = true;
    //     let result = await axios.get(
    //       process.env.VUE_APP_API_URL + `/report/get-complete-report/${this.userData.objectId}`,

    //     );
    //     console.log(result);
    //     if (result.status == 200) {
    //       this.getLoad = false;
    //       this.records = result.data.data;
    //       // this.reset();
    //     } else {
    //       this.getLoad = false;
    //       this.records = [];
    //     }
    //   } catch (e) {
    //     this.getLoad = false;
    //     console.log(e);
    //   }
    // },
    logOut() {
      this.$swal({
        title: "Terminar esta sessão?",
        showCancelButton: true,
        confirmButtonColor: "#026447",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          self.$router.push("/login");
          this.profile = "";
        }
      });
    },
  },
});
</script>

<style>
.circleimage {
  border-radius: 50%;
}

.mainheader .dropdown-menu {
  background-color: #2c3e50 !important;
  overflow-y: scroll;
  max-height: 300px;
}

</style>