var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"mainheader py-3"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"d-flex col-7 align-items-center",attrs:{"md":"5","xl":"3"}},[_c('a',{attrs:{"href":"/login"}},[_c('img',{staticClass:"mainlogo",attrs:{"alt":"logo","src":require("../assets/images/cemig-logo.svg")}})]),_c('span',{staticClass:"slogan"},[_vm._v(" Seu médico sem sair de casa ")])]),_c('b-col',{staticClass:"d-flex align-items-center col-5 justify-content-end mt-1",attrs:{"md":"7","xl":"9"}},[(
            this.$route.name == 'LoginScreen' || this.$route.name == 'ForgetPassword' ||
            this.$route.name == 'ResetPassword' || this.$route.name == 'HOMEROUTE'
          )?[_c('h6',{staticClass:"headline m-0 w-100 text-center"},[_vm._v(" Cadastro e relatórios de novos usuários ")])]:_vm._e(),_c('b-navbar',{staticClass:"navlinks py-0",attrs:{"toggleable":"lg","type":"dark"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),(
              this.$route.name != 'LoginScreen' &&
              this.$route.name != 'ResetPassword' && 
              this.$route.name != 'ForgetPassword' && this.$route.name != 'HOMEROUTE'
            )?_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":"/home"}},[_c('img',{attrs:{"src":require("../assets/images/groupicon.svg"),"alt":""}}),_vm._v(" Cadastro de usuários")]),_c('b-nav-item',{attrs:{"to":"/report"}},[_c('img',{attrs:{"src":require("../assets/images/docicon.svg"),"alt":""}}),_vm._v(" Relatórios")]),_c('b-nav-item',{attrs:{"href":"#"}},[(_vm.profile == '')?_c('img',{attrs:{"src":require("../assets/images/profile.svg"),"alt":""}}):_c('img',{ref:"profile",staticClass:"circleimage",attrs:{"src":_vm.profile,"width":"50px","height":"50px","mr-0":""}})]),_c('b-nav-item',{on:{"click":function($event){return _vm.logOut()}}},[_vm._v("Sair")])],1),(_vm.records.length > 0)?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Download","right":""}},_vm._l((_vm.records),function(rec){return _c('b-dropdown-item',{key:rec._id,staticClass:"nav-dropdown",on:{"click":function($event){return _vm.updateStatus(rec)}}},[_vm._v(_vm._s(rec.fileName))])}),1)],1):_vm._e()],1):_vm._e()],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }