<template>
  <footer class="footermain py-2">
    <b-container class="bv-example-row">
      <b-row>
        <b-col cols="2" md="4" class="d-flex align-items-start">
        <div    class="chaticon w-30"></div>
          <!-- <img
            alt="Vue logo"
            class="chaticon"
            src="../assets/images/chat-icon.svg"
          /> -->
          <div class="textdiv mt-2 d-none d-md-block">
            <h4>Plataforma Iron Telemedicina</h4>
            <span>CNES: 0189650</span>
          </div>
        </b-col>
        <b-col
          cols="10"
          md="4"
          class="text-center d-flex align-items-center justify-content-end justify-content-md-center"
        >
          <div class="sociallinks w-100">
            <b-link href="https://www.facebook.com/cemigsaude/" target="_blank">
              <img alt="facebook" src="../assets/images/facebook.svg" />
            </b-link>
            <b-link href="https://www.instagram.com/cemigsaude/" target="_blank">
              <img alt="facebook" src="../assets/images/instagram.svg" />
            </b-link>
            <b-link href="https://www.linkedin.com/company/cemigsaude/" target="_blank">
              <img alt="facebook" src="../assets/images/linkedin.svg" />
            </b-link>
            <b-link href="https://www.youtube.com/c/CemigSa%C3%BAdeOficial" target="_blank">
              <img alt="facebook" src="../assets/images/youtube.svg" />
            </b-link>
          </div>
        </b-col>

        <b-col cols="7" class="d-md-none">
          <div class="textdiv mt-2">
            <h4>Plataforma Iron Telemedicina</h4>
            <span>CNES: 0189650</span>
          </div>
        </b-col>
        <b-col
          cols="5"
          md="4"
          class="d-flex align-items-center justify-content-end flex-column flex-lg-row"
        >
          <img
            src="../assets/images/ans-logo.svg"
            class="anslogo"
            alt="ans logo"
          />
          <span class="anstext">ANS - Nº 417505 </span>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
// import { computed } from 'vue'

export default {
  name: "Fooder",
  data() {
    return {
      year: "",
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
};
</script>
<style scoped>
.w-30{
width: 30% !important;
}
</style>