<template>
  <div id="app">
    <template>
      <app-header :records="records" @getfiltes="getReports()" />
    </template>
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import commonMixin from "./utils/commonMixin.js";
let self;
import AppFooter from "@/components/AppFooter.vue";
import axios from "axios";
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  mixins: [commonMixin],
  data() {
    return {
      getLoad: false,
      records: [],
      userData: {},
    };
  },
  created() {},
  mounted() {
    self = this;
    if (
      this.$route.name != "LoginScreen" &&
      this.$route.name != "ResetPassword" &&
      this.$route.name != "ForgetPassword" &&
      this.$route.name != "HOMEROUTE"
    ) {
      let userData = JSON.parse(localStorage.getItem("userObj"));
      this.userData = userData;
      this.getReports();
    }
    // this.getReports()
  },
  methods: {
    async getReports() {
      try {
        this.getLoad = true;
        let result = await axios.get(
          process.env.VUE_APP_API_URL +
            `/report/get-complete-report/${this.userData.objectId}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );
        console.log(result);
        if (result.status == 200) {
          this.getLoad = false;
          this.records = result.data.data;
          // this.reset();
        } else {
          this.getLoad = false;
          this.records = [];
        }
      } catch (e) {
        this.getLoad = false;
        console.log(e.response);
        self.errorHandle(e.response);
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
