global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export const notification = (text, icon, config = {}) => {
    var loaderBg = (icon === "warning" )? "#f8c607" : (icon === "success" ? "#3bb54a" : "#e04f5f")
    var classNameTitle = (icon === "warning") ? "toast-title-warning" : icon === "success" ? "toast-title-success" : "toast-title-error";
    var classToastIcon = (icon === "warning") ? "toast-icon-warning" : icon === "success" ? "toast-icon-success" : "toast-icon-error";
    var classIcon = (icon === "warning") ? '"toast-warning fas fa-exclamation-triangle"' : icon === "success" ? '"toast-success fas fa-check-circle"' : '"toast-error fas fa-times-circle"';
    var title = " ";//(icon == "warning") ? "ATENÇÃO!" : (icon == "success" ? "CONCLUÍDO!" : "ERRO!")
    var msgTitle = (icon == "warning") ? "ATENÇÃO!" : (icon == "success" ? "CONCLUÍDO!" : "ERRO!")
    var tostDetails = "<div class=" + classToastIcon + '><i class=' +
        classIcon + '/></div><div class="CallingPopUpArea align-items-center d-flex  p-3"><div class="toast-content"><h3 class='
        + classNameTitle +
        ">" +        
        title +
        '</h3><p class="toast-icon-content">' +
         msgTitle +"<br>"+
        text +
        "</p></div></div>";
    config = {
        ...config,
        text: tostDetails,
        showHideTransition: "plain",
        position: "top-right",
        hideAfter : 5000,       
        loaderBg
    }

    $.toast(
        config,
        {}
    );

}

export const alertNotification = (text, config = {}) => {

    var tostDetails = "<div class='main-Container'><div class='align-items-center d-flex justify-content-start'><div><i class='fas fa-exclamation-triangle'/></div><div class='ml-2'><h4 class='title-container'>ATENÇÃO!</h4></div></div><div class='text-container'><p>" + 
    text + "</p></div></div>";

    config = {
        ...config,
        text: tostDetails,
        showHideTransition: "plain",
        position: "top-right",
        loaderBg:"#F1B3B4"
    }

    $.toast(
        config,
        {}
    );

}