import Vue from "vue";
import VueRouter from "vue-router";
import HomeContainer from "../components/HomeContainer.vue";
import LoginScreen from "../components/LoginScreen.vue";
import ResetPassword from "../components/ResetPassword.vue";
import HistoryLog from "../components/HistoryLog.vue";
import Report from "../components/Report.vue";
import AddStaff from "../components/AddStaff.vue";
import ForgetPassword from "../components/ForgetPassword.vue";

Vue.use(VueRouter);

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
 if(localStorage.getItem('token'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
if(isAuthenticated) {
  next(); // allow to enter route
 } else{
  next('/login'); // go to '/login';
 }
}

const routes = [
  {
    path: "/",
    name: "HOMEROUTE",
    component: LoginScreen,
    
  },
  {
    path: "/login",
    name: "LoginScreen",
    component: LoginScreen,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    // beforeEnter : guardMyroute,
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    // beforeEnter : guardMyroute,
    component: ResetPassword,
  },
  {
    path: "/add-staff",
    name: "AddStaff",
    beforeEnter : guardMyroute,
    component: AddStaff,
  },
  {
    path: "/edit-staff",
    name: "EditStaff",
    beforeEnter : guardMyroute,
    component: AddStaff,
  },
  {
    path: "/home",
    name: "HomeContainer",
    beforeEnter : guardMyroute,
    component: HomeContainer,
  },
  {
    path: "/history",
    name: "HistoryLog",
    beforeEnter : guardMyroute,
    component: HistoryLog,
  },
  {
    path: "/report",
    name: "Report",
    beforeEnter : guardMyroute,
    component: Report,
  },
  {
    path: "/about",
    name: "About",
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
