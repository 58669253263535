var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"resetpassword"},[[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center justify-content-end",attrs:{"md":"5"}},[_c('img',{staticClass:"passwordimg",attrs:{"src":require("../assets/images/password-img.svg"),"alt":""}})]),_c('b-col',{staticClass:"mx-auto",attrs:{"md":"7"}},[_c('div',{staticClass:"mainbox-form"},[_c('div',{staticClass:"topheading mb-4"},[_c('h2',{staticClass:"heading"},[_vm._v("Defina sua nova senha")])]),(_vm.show)?_c('b-form',[_c('b-form-group',{attrs:{"id":"input-group-2"}},[_c('b-form-input',{attrs:{"type":_vm.type,"placeholder":" ","id":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('label',{staticClass:"subtext"},[_vm._v("Senha")]),(_vm.type == 'password')?[_c('img',{staticClass:"showpassword",attrs:{"src":require("../assets/images/eye.svg"),"alt":"password"},on:{"click":_vm.showPassword}})]:_vm._e(),(_vm.type != 'password')?[_c('img',{staticClass:"showpassword",attrs:{"src":require("../assets/images/eye-hide.svg"),"alt":"password"},on:{"click":_vm.showPassword}})]:_vm._e()],2),_c('b-progress',{staticClass:"mb-3",attrs:{"value":_vm.value,"max":_vm.max}}),_c('ul',{staticClass:"select-selection"},[_c('li',{style:([
                    _vm.isAlphaNumerci ? { 'font-weight': 'bold' } : {},
                  ])},[_c('span',{staticClass:"check",style:([
                      _vm.isAlphaNumerci
                        ? {
                            'border-bottom': '2px solid #4ca344',
                            'border-right': '2px solid #4ca344',
                          }
                        : {
                            'border-bottom': '2px solid var(--lightcolor1)',
                            'border-right': '2px solid var(--lightcolor1)',
                          },
                    ])}),_vm._v(" Letras e números ")]),_c('li',{style:([_vm.isCapital ? { 'font-weight': 'bold' } : {}])},[_c('span',{staticClass:"check",style:([
                      _vm.isCapital
                        ? {
                            'border-bottom': '2px solid #4ca344',
                            'border-right': '2px solid #4ca344',
                          }
                        : {
                            'border-bottom': '2px solid var(--lightcolor1)',
                            'border-right': '2px solid var(--lightcolor1)',
                          },
                    ])}),_vm._v(" Letras maiúsculas ")]),_c('li',{style:([_vm.isLower ? { 'font-weight': 'bold' } : {}])},[_c('span',{staticClass:"check",style:([
                      _vm.isLower
                        ? {
                            'border-bottom': '2px solid #4ca344',
                            'border-right': '2px solid #4ca344',
                          }
                        : {},
                    ])}),_vm._v(" Letras minúsculas ")]),_c('li',{style:([_vm.isLength ? { 'font-weight': 'bold' } : {}])},[_c('span',{staticClass:"check",style:([
                      _vm.isLength
                        ? {
                            'border-bottom': '2px solid #4ca344',
                            'border-right': '2px solid #4ca344',
                          }
                        : {
                            'border-bottom': '2px solid var(--lightcolor1)',
                            'border-right': '2px solid var(--lightcolor1)',
                          },
                    ])}),_vm._v(" 10 caracteres ")]),_c('li',{style:([_vm.isSpecial ? { 'font-weight': 'bold' } : {}])},[_c('span',{staticClass:"check",style:([
                      _vm.isSpecial
                        ? {
                            'border-bottom': '2px solid #4ca344',
                            'border-right': '2px solid #4ca344',
                          }
                        : {
                            'border-bottom': '2px solid var(--lightcolor1)',
                            'border-right': '2px solid var(--lightcolor1)',
                          },
                    ])}),_vm._v(" Caracter especial ")])]),_c('b-button',{staticClass:"w-100 intrarbutton",attrs:{"pill":"","block":"","type":"button","variant":"primary","disabled":!_vm.isValid},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v("Continuar")])],1):_vm._e()],1)])],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }