<template>
  <div class="homescreen">
    <b-container>
      <b-row>
        <div class="moddlebox">
          <h2 class="hometitle" v-if="userType && userType != 'staff'">
            Cadastro e relatórios de novos usuários
          </h2>
          <b-row v-if="userType && userType != 'staff'">
            <b-col sm="6" class="px-4 mb-4 mb-md-0">
              <img
                src="../assets/images/member-icon.svg"
                class="icon"
                alt="member"
              />
              <label
                >Cadastre novos usuários e configure permissões
                especificas</label
              >

              <b-button
                pill
                block
                class="w-100 cadastrar"
                variant="success"
                type="button"
                @click="addUser()"
                >Cadastrar novos usuários</b-button
              >
            </b-col>
            <b-col sm="6" class="px-4">
              <img
                src="../assets/images/clock-icon.svg"
                class="icon"
                alt="clock"
              />
              <label>Saiba mais detalhes das informações de histórico</label>

              <b-button
                pill
                block
                class="w-100 visualizar"
                variant="warning"
                @click="GoToHistory()"
                >Visualizar histórico de alterações</b-button
              ></b-col
            >
          </b-row>
          <b-row>
            <div class="searchbox mt-5 p-0">
              <b-form class="px-3 px-md-5 py-4" inline>
                <b-row>
                  <b-col md="6" class="">
                    <b-form-group>
                      <label>Pesquisar usuário </label>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <img
                              src="../assets/images/search-icon.svg"
                              alt=""
                              class="searchicon"
                            />
                          </div>
                        </div>
                        <b-form-input
                          aria-label="Search here..."
                          placeholder="Pesquisar"
                          v-model="searchValue"
                          type="text"
                          @enter="searchName(searchValue)"
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    xl="5"
                    class="rightbuttons d-flex align-items-end pb-1 justify-content-center mt-3 mt-md-0"
                  >
                    <button
                      class="name"
                      @click="searchName($event)"
                      :disabled="loader"
                    >
                      Nome
                    </button>
                    <span class="loader" v-if="loaderSearch"></span>
                    <!-- <b-button pill variant="success" class="btn-sm perfill"
                      >Perfil de acesso</b-button
                    > -->
                  </b-col>
                </b-row>
              </b-form>
              <b-row class="m-0">
                <div class="table-responsive mb-2 px-0">
                  <table
                    class="table table-hover table-striped"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Nome</th>
                        <th v-if="userType != 'staff'">CPF</th>
                        <th>Email</th>
                        <th>Cadastrado por</th>
                        <th v-if="userType && userType != 'staff'">
                          <span
                            class="loader"
                            v-if="deleteLoad || (loader && isDelete)"
                          ></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-if="getStaffData.length <= 0">
                        <td colspan="6">
                          Não há registro
                          <span class="loader" v-if="loader"></span>
                        </td>
                      </tr>
                      <tr v-for="(staff, index) in getStaffData" :key="index">
                        <td class="blank-td"></td>
                        <td class="sm-col">{{ staff.name }}</td>
                        <td v-if="userType != 'staff'" class="sm-col">
                          {{ staff.cpf }}
                        </td>
                        <td class="sm-col">{{ staff.email }}</td>
                        <td class="sm-col">{{ staff.createdByName }}</td>
                        <td
                          class="sm-col"
                          v-if="userType && userType != 'staff'"
                        >
                          <b-button
                            v-if="staff.isBlocked == false"
                            pill
                            variant="success"
                            class="btn-sm editar px-2 px-md-4"
                            @click="editStaff(staff)"
                            >Editar</b-button
                          >
                          <b-button
                            v-if="staff.isBlocked == true"
                            pill
                            variant="danger"
                            class="btn-sm inativar px-2 px-md-4"
                            @click="activeInactiveUser(!staff.isBlocked, staff)"
                            >Ativar</b-button
                          >
                          <b-button
                            v-if="staff.isBlocked == false"
                            pill
                            variant="danger"
                            class="btn-sm inativar px-2 px-md-4"
                            @click="activeInactiveUser(!staff.isBlocked, staff)"
                            >Inativar</b-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-row>
            </div>
          </b-row>
          <div
            v-if="getStaffData.length && total > 5"
            class="pagination-block text-center my-1"
          >
            <paginate
              :value="current_page"
              :page-count="Math.ceil(total / limit)"
              :page-range="1"
              :click-handler="onChangePage"
              :prev-text="''"
              :next-text="''"
              :container-class="'pagination justify-content-center m-0'"
              :page-class="'page-item'"
            ></paginate>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import commonMixin from "../utils/commonMixin";
// import { notification } from '../utils/customToast';
const debounce = require("lodash/debounce");
// import AppHeader from '@/components/AppHeader.vue'
// import AppFooter from '@/components/AppFooter.vue'
export default {
  name: "Home",
  // NAME: "HomeContainer",
  mixins: [commonMixin],
  components: {
    // AppHeader,
    // AppFooter
  },
  data() {
    return {
      page_title: "Home - CEMIG",
      getStaffData: [],
      searchValue: "",
      loader: false,
      loaderSearch: false,
      total: 0,
      current_page: 1,
      limit: 10,
      deleteLoad: false,
      isDelete: false,
      userType: "",
    };
  },
  methods: {
    onChangePage(pageNum) {
      this.current_page = pageNum;
      this.getAllStaff();
    },

    async searchName(e) {
      e.preventDefault();
      if (this.searchValue.length > 0) {
        this.loaderSearch = true;
        this.fetchSearch(this);
      }
    },
    fetchSearch: debounce(async (_this) => {
      const q = JSON.stringify(_this.searchValue);
      if (q.length <= 0) {
        _this.getAllStaff();
        return;
      }
      let token = localStorage.getItem("token");
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/user/getAllData?is_pagination=true&page=${_this.current_page}&limit=${_this.limit}&q=${q}`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          _this.current_page = 1;
          if (response) {
            _this.loaderSearch = false;
            _this.getStaffData = response.data.data;
          }
        })
        .catch((err) => {
          _this.searchLoad = false;
          _this.loaderSearch = false;
          _this.errorHandle(err.response);
        });
    }, 500),

    async getAllStaff() {
      try {
        this.loader = true;
        let token = localStorage.getItem("token");
        let result = await axios.get(
          process.env.VUE_APP_API_URL +
            `/user/getAllData?is_pagination=true&page=${this.current_page}&limit=${this.limit}&q=""`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (result.status == 200) {
          this.getStaffData = result.data.data;
          this.total = result.data.total;
          this.loader = false;
          this.isDelete = false;
        }
      } catch (e) {
        this.loader = false;
        this.isDelete = false;
        // this.errorHandle(e.response)
      }
    },
    async editStaff(staff) {
      if (localStorage.getItem("editUser") != null) {
        localStorage.removeItem("editUser");
      }
      if (localStorage.getItem("userData") != null) {
        localStorage.removeItem("userData");
      }
      localStorage.setItem("editUser", JSON.stringify(staff));
      this.$router.push("/edit-staff");
    },
    addUser() {
      if (localStorage.getItem("editUser") != null) {
        localStorage.removeItem("editUser");
      }
      if (localStorage.getItem("userData") != null) {
        localStorage.removeItem("userData");
      }
      this.$router.push("/add-staff");
    },
    async activeInactiveUser(isBlock, staff) {
      let title =
        isBlock != true
          ? "Deseja ativar o usuário?"
          : "Deseja desativar o usuário?";
      this.$swal({
        title: title,
        showCancelButton: true,
        confirmButtonColor: "#026447",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isDelete = true;
          this.activeInactive(staff);
        }
      });
    },
    async activeInactive(staff) {
      try {
        this.deleteLoad = true;
        let result = await axios.delete(
          process.env.VUE_APP_API_URL + `/user/delete-user/${staff.id}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );
        if (result.status == 200) {
          this.getAllStaff();
        }
        this.deleteLoad = false;
      } catch (e) {
        this.deleteLoad = false;
        this.errorHandle(e.response);
      }
    },
    GoToHistory() {
      this.$router.push("/history");
    },
  },
  mounted() {
    if (localStorage.getItem("userObj") != null) {
      let user = JSON.parse(localStorage.getItem("userObj"));
      if (user) {
        if (user.roles.indexOf("admin") != -1) {
          this.userType = "admin";
        } else {
          this.userType = "staff";
        }
      }
    }
    this.getAllStaff();
    this.isValidLogin();
  },
};
</script>

<style></style>
