<template>
  <div class="loginarea">
    <b-container>
      <b-row>
        <b-col
          md="5"
          class="d-none text-right d-md-flex align-items-center justify-content-end"
        >
          <!-- <b-img src="" alt=""></b-img> -->
          <img
            class="loginvector ml-auto"
            src="../assets/images/login-vector.svg"
          />
        </b-col>
        <b-col md="7">
          <div class="mainbox-form">
            <div class="topheading mb-5">
              <h2 class="heading">Esqueceu a senha</h2>
            </div>
            <b-form v-if="show">
              <b-form-group id="input-group-1">
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="email"
                  placeholder="E-mail"
                ></b-form-input>
                <label class="subtext">Digite o e-mail</label>
              </b-form-group>

              <p style="background-color: red">{{ errorMsg }}</p>
              <b-form-group>
                <b-button
                  pill
                  block
                  class="w-100 intrarbutton"
                  type="submit"
                  variant="primary"
                  @click="buttonClick()"
                  :disabled="!isValidEmail || loader"
                  >Entrar</b-button
                ><span class="loader" v-if="loader"></span>
              </b-form-group>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import commonMixin from "../utils/commonMixin";
import { notification as notificationToast } from "../utils/customToast";
let self;

export default Vue.extend({
  name: "ForgetPassword",

  mixins: [commonMixin],
  components: {},
  data() {
    return {
      page_title: "ForgetPassword - CEMIG",
      errorMsg: "",
      email: "",
      isValidEmail: false,
      show: true,
      type: "password",
      loader: false,
      email_error: false,
    };
  },
  watch: {
    email: function (value) {
      this.isValidEmail = this.checkEmail(value);
    },
  },
  mounted() {
    self = this;

    // notificationToast('MY MESSG1','success')
    // notificationToast('MY MESSG2','warning')
    // notificationToast('MY MESSG3','error')

    if (localStorage.getItem("token") != null) {
      if (localStorage.getItem("userObj") != null) {
        var userObj = JSON.parse(localStorage.getItem("userObj"));
        if (userObj.is_first_login == true) {
          this.$router.push("/reset-password");
        } else {
          this.$router.push("/home");
        }
      }

      // this.$router.push("/home");
    }
  },
  methods: {
    checkEmail: function (value) {
      var emailRegex = /\S+@\S+\.\S+/;
      var hasWhiteSpace = /\s/g;
      if (emailRegex.test(value) && !hasWhiteSpace.test(value)) {
        return true;
      }
    },
    buttonClick: function () {
      self = this;
      self.errorMsg = "";
      this.loader = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/forgetpassword", {
          email: this.email.trim(),
        })
        .then((response) => {
          if (response.status == 200) {
            this.loader = false;
            this.$router.push("/login");
            notificationToast(response.data.message, "warning");
          } else {
            // error Alert
            notificationToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          self.loader = false;
          if (error.response) {
            if (error.response.status != 401) {
              notificationToast(error.response.data.message, "error");
            } else {
              this.errorHandle(error.response);
            }
          } else {
            notificationToast(error.response.data.message, "error");
          }
        });
    },
  },
});
</script>
