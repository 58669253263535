<template>

  <div class="addstaff">
    <b-container>
      <b-row>
        <h2 class="headind">Cadastrar novos usuários</h2>

        <b-tabs content-class="mt-3" justified>
          <b-tab
            title="ETAPA I - Dados do novo usuário"
            :active="showDetail"
            @click="changeTabs('tab-1')"
          >
            <b-form class="formdiv" v-if="!showDetail">
              <div class="addstaff-img">
                <div>
                  <img
                    v-if="!profile"
                    src="../assets/images/profile-user.svg"
                    class="user-img"
                  />
                  <img
                    v-if="profile"
                    :src="profile"
                    width="50px"
                    height="50px"
                    class="circleimage"
                    ref="profile"
                  />
                  <input
                    id="file-upload"
                    accept="image/png, image/gif, image/jpeg"
                    type="file"
                    hidden
                    @change="fileSelect"
                    ref="file"
                  />
                  <label for="file-upload" class="upload-icon">
                    <img src="../assets/images/edit-icon.svg" />
                  </label>
                </div>
                <div>
                  <span v-if="isProfileErrorShow" class="d-block text-danger"
                    >Selecione apenas arquivos de imagem</span
                  >
                </div>
              </div>

              <b-row>
                <b-col md="6">
                  <b-form-group label="Nome">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <img
                            src="../assets/images/usericon.svg"
                            alt=""
                            class="searchicon"
                          />
                        </div>
                      </div>
                      <b-form-input
                        v-model="form.name"
                        placeholder="Digite o nome"
                      ></b-form-input>
                    </div>
                    <div class="validation-message">{{ form.name_error }}</div>
                  </b-form-group>

                  <b-form-group label="Data de nascimento">
                    <div class="input-group calendardiv">
                      <b-input-group class="mb-3">
                        <b-form-input
                          id="example-input"
                          v-model="fomateData"
                          type="text"
                          placeholder="DD-MM-YYYY"
                          autocomplete="off"
                          @blur="handleInput($event)"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="form.birthdate"
                            button-only
                            right
                            locale="en-US"
                            :max="new Date()"
                            aria-controls="example-input"
                            @context="onContext"
                            :date-format-options="{
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            }"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                      <div class="validation-message" v-if="form.date_error">
                        {{ form.date_error }}
                      </div>
                    </div>
                    <div class="validation-message">
                      {{ form.birthdate_error }}
                    </div>
                  </b-form-group>

                 
                  <div class="row">
                    <div class="col-12 text-start">
                    <input type="checkbox" id="checkbox" v-model="addAllClinc">
                    <label for="checkbox">Adicionar todos</label>
                  </div>
                    <label
                    
                    class="plusdivmain col-12"
                    style="text-align: left; color: black"
                    label-for="name"
                  >
                  Cadastro de estabelecimento
                  </label>
                    

                  </div>
                  
                  
                    <div class="col-12">
                      
                      <multiselect :disabled="addAllClinc" v-model="form.selected" tag-placeholder="Add this as new tag" placeholder="Selecione o estabelecimento" label="name" track-by="name" :options="clinicList" :multiple="true" :taggable="true" :searchable="false" :hideSelected="true" :close-on-select="false" :preselect-first="true" :clear-on-select="true"></multiselect>
                      <div class="validation-message" style="color: red;text-align: left;">
                        {{ form.selected_error }}
                      </div>
                    </div>
                    
                  
                  
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nº CPF">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <img
                            src="../assets/images/cpf-icon.svg"
                            alt=""
                            class="searchicon"
                          />
                        </div>
                      </div>
                      <b-form-input
                        v-model="form.cpf"
                        v-mask="'###.###.###-##'"
                        placeholder="000.000.000-00"
                      ></b-form-input>
                    </div>
                    <div class="validation-message">{{ form.cpf_error }}</div>
                  </b-form-group>
                  <!-- <b-form-group label="Nº de matrícula">
                                                                                <div class="input-group">
                                                                                  <div class="input-group-prepend">
                                                                                    <div class="input-group-text">
                                                                                      <img
                                                                                        src="../assets/images/cpf-icon.svg"
                                                                                        alt=""
                                                                                        class="searchicon"
                                                                                      />
                                                                                    </div>
                                                                                  </div>
                                                                                  <b-form-input
                                                                                    v-model="form.matrícula"
                                                                                    placeholder="000000"
                                                                                  ></b-form-input>
                                                                                </div>
                                                                                <div class="validation-message">{{ form.matrícula_error }}</div>
                                                                              </b-form-group> -->

                  <b-form-group label="Email">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <img
                            src="../assets/images/email.svg"
                            alt=""
                            class="searchicon"
                          />
                        </div>
                      </div>
                      <b-form-input
                        v-model="form.email"
                        placeholder="exemplo@provedor.com"
                      ></b-form-input>
                    </div>
                    <div class="validation-message">{{ form.email_error }}</div>
                  </b-form-group>

                  <b-form-row class="twocolumn none-backimg mx-0 mb-3 mb-md-0">
                    <div class="col-8">
                      <b-form-group
                        class="ms-0"
                        label="Nº conselho regional"
                        :style="[
                          form.crm_error
                            ? { marginTop: 18.5 + 'px' }
                            : { marginTop: 0 },
                        ]"
                      >
                        <div class="input-group">
                          <!-- <div class="input-group-prepend">
                                                                                      <div class="input-group-text">
                                                                                        <img
                                                                                          src="../assets/images/cpf-icon.svg"
                                                                                          alt=""
                                                                                          class="searchicon"
                                                                                        />
                                                                                      </div>
                                                                                    </div> -->
                          <b-form-input
                            v-model="form.regional_code"
                            placeholder="A000001"
                            maxlength="6"
                          ></b-form-input>
                        </div>
                        <div class="validation-message">
                          {{ form.regional_code_error }}
                        </div>
                      </b-form-group>
                    </div>

                    <!-- <b-form-group label="CBO" :style="[form.cbo_error ? {marginTop:18.5 + 'px'} : {marginTop:0}]">
                                                                                  <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                      <div class="input-group-text">
                                                                                        <img
                                                                                          src="../assets/images/cpf-icon.svg"
                                                                                          alt=""
                                                                                          class="searchicon"
                                                                                        />
                                                                                      </div>
                                                                                    </div>
                                                                                    <b-form-input
                                                                                      v-model="form.cbo"
                                                                                      placeholder="5412"
                                                                                      maxlength="6"
                                                                                    ></b-form-input>
                                                                                  </div>
                                                                                  <div class="validation-message">{{ form.cbo_error }}</div>
                                                                                </b-form-group> -->
                    <div class="col-4 right-selectdiv">
                      <b-form-group label="Estado" class="w-100 mx-0">
                        <v-select
                          id="Estado"
                          class="style-chooser w-110 mw-100"
                          placeholder="UF"
                          v-model="form.Estado"
                          :clearable="false"
                          :options="stateList"
                          label="state_code"
                        >
                          <span slot="no-options">
                            Desculpe, nenhuma opção correspondente.</span
                          >
                        </v-select>
                        <div class="validation-message">
                          {{ form.Estado_error }}
                        </div>
                      </b-form-group>
                    </div>
                  </b-form-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Telefone (Opcional)">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <img
                            src="../assets/images/number.svg"
                            alt=""
                            class="searchicon"
                          />
                        </div>
                      </div>
                      <b-form-input
                        v-model="form.phone"
                        v-mask="mask"
                        @keypress="check_validation($event)"
                        placeholder="DDD + número"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Tipo de usuário">
                    <v-select
                      id="userType"
                      class="style-chooser w-110 mw-100"
                      placeholder="Selecione o tipo de usuário"
                      v-model="form.userType"
                      :options="selectOptions"
                      :clearable="false"
                      label="text"
                      v-on:input="getOptions"
                    >
                      <span slot="no-options">
                        Desculpe, nenhuma opção correspondente.</span
                      >
                    </v-select>
                    <div class="validation-message">
                      {{ form.usertype_error }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <div class="position-relative">
              <b-form-group class="buttonbottom">
                <b-button
                  pill
                  variant="light"
                  class="voltar"
                  type="button"
                  @click="backToList()"
                  >Voltar</b-button
                >

                <b-button
                  pill
                  variant="success"
                  class="download"
                  @click="save()"
                  type="button"
                  :disabled="loader"
                  >Ir para checkout</b-button
                >
              </b-form-group>
              <span class="loader addStaff-loader d-none d-lg-block" v-if="loader"></span>
            </div>
          </b-tab>

          <b-tab
            title="ETAPA II - Checkout"
            :active="showDetail"
            @click="changeTabs('tab-2')"
            :disabled="!showDetail"
          >
            <div class="tableyellow-fixed" v-if="showDetail">
              <h5 class="toptitle">Dados do usuário</h5>
              <b-table-simple>
                <b-tbody>
                  <b-tr>
                    <b-td>Nome</b-td>
                    <b-td
                      ><strong>{{ this.form.name }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>CPF*</b-td>
                    <b-td
                      ><strong>{{ this.form.cpf }}</strong></b-td
                    >
                  </b-tr>
                  <!-- <b-tr>
                                                                               <b-td>matrícula*</b-td>
                                                                                <b-td
                                                                                  ><strong>{{ this.form.matrícula }}</strong></b-td
                                                                                >
                                                                              </b-tr> -->
                  <b-tr>
                    <b-td>Data de nascimento</b-td>
                    <b-td
                      ><strong>{{ this.form.birthdate }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>Email*</b-td>
                    <b-td
                      ><strong>{{ this.form.email }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>Telefone</b-td>
                    <b-td
                      ><strong>{{
                        this.form.phone ? this.form.phone : "-"
                      }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>Cadastrar estabelecimento</b-td>
                    <b-td class="one-long-line"
                      ><strong>{{ this.selected_clinic }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>Nº Conselho Regional</b-td>
                    <b-td
                      ><strong>{{
                        this.form.regional_code ? this.form.regional_code : "-"
                      }}</strong></b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td>Estado</b-td>
                    <b-td
                      ><strong>{{
                       this.form.Estado ? this.form.Estado.state : "-"
                      }}</strong></b-td
                    >
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <label class="bottomdes">
                *Para o primeiro acesso do usuário o login será feito
                através do email e a senha para o primeiro acesso será o número de CPF
              </label>
            </div>

            <b-form-group class="buttonbottom">
              <div class="add-staff-btn">
                <b-button
                  pill
                  variant="light"
                  class="voltar"
                  type="button"
                  @click="back()"
                  >Voltar</b-button
                >
                <div class="position-relative">
                  <b-button
                    pill
                    variant="success"
                    class="download"
                    type="button"
                    @click="addStaff()"
                    >Concluir cadastro</b-button
                  ><span class="loader addStaff-loader" v-if="loader"></span>
                </div>
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs>

        <b-modal
          id="modal-center"
          hide-header
          hide-footer
          centered
          title="BootstrapVue"
          @hidden="resetClinic()"
        >
          <h2 class="modaltitle mb-5 mt-4">Cadastrar nova estabelecimento</h2>
          <b-form class="modalform">
            <b-form-group label="Digite o nome do estabelecimento">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img
                      src="../assets/images/home-icon.svg"
                      alt=""
                      class="homeicon"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model="ClinicalForm.name"
                  placeholder="Cadastrar estabelecimento"
                ></b-form-input>
              </div>
              <div class="custom-text-red">{{ ClinicalForm.name_error }}</div>
            </b-form-group>
            <b-form-group label="CNPJ">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img
                      src="../assets/images/cpf-icon.svg"
                      alt=""
                      class="searchicon"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model="ClinicalForm.cnpj"
                  v-mask="'##.###.###/####-##'"
                  placeholder="Digite o número do CNPJ"
                  @change="checkCNPJ()"
                ></b-form-input>
              </div>
              <div class="custom-text-red">{{ ClinicalForm.cnpj_error }}</div>
            </b-form-group>
            <b-form-group label="Nome do responsável">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img
                      src="../assets/images/usericon.svg"
                      alt=""
                      class="searchicon"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model="ClinicalForm.responsibleName"
                  placeholder="Digite o nome do responsável"
                ></b-form-input>
              </div>
              <div class="custom-text-red">
                {{ ClinicalForm.responsibleName_error }}
              </div>
            </b-form-group>
            <b-form-group label="E-mail do responsável">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img
                      src="../assets/images/email.svg"
                      alt=""
                      class="searchicon"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model="ClinicalForm.email"
                  placeholder="Digite o e-mail do responsável"
                ></b-form-input>
              </div>
              <div class="custom-text-red">{{ ClinicalForm.email_error }}</div>
            </b-form-group>
            <b-form-group label="Telefone">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img
                      src="../assets/images/number.svg"
                      alt=""
                      class="searchicon"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model="ClinicalForm.telephone"
                  v-mask="clinic_mask"
                  @keypress="check_validation($event)"
                  placeholder="DDD + número"
                ></b-form-input>
              </div>
              <div class="custom-text-red">
                {{ ClinicalForm.telephone_error }}
              </div>
            </b-form-group>

            <b-form-group class="buttonbottom">
              <b-button
                pill
                variant="success"
                class="salvar mb-3"
                :disabled="clinicLoader"
                @click="addClinic($event)"
                >Salvar</b-button
              ><span class="loader" v-if="clinicLoader"></span>
              <b-button
                @click="resetClinic()"
                pill
                variant="light"
                class="cancelar"
                >Cancelar</b-button
              >
            </b-form-group>
          </b-form>
        </b-modal>

        <!-- <button @click="open">Show toast</button> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import commonMixin from "../utils/commonMixin";
import { notification } from "../utils/customToast";
import Multiselect from 'vue-multiselect'

// import { notification } from "../utils/customToast";
const moment = require("moment");

export default Vue.extend({
  name: "AddStaff",
  components:{
    Multiselect
  },
  mixins: [commonMixin],
  data() {
    return {
      addAllClinc: false,
      page_title: "Add Staff - CEMIG",
      fomateData: "",
      ClinicalForm: {
        email: "",
        email_error: "",
        name: "",
        name_error: "",
        cnpj: "",
        cnpj_error: "",
        responsibleName: "",
        responsibleName_error: "",
        telephone: "",
        telephone_error: "",
        selected_clinic: "",
      },
      form: {
        name: "",
        name_error: "",
        birthdate: "",
        birthdate_error: "",
        selected: [],
        selected_error: "",
        Estado: {
          state: "",
          state_code: "",
          _id: ""
        },
        Estado_error: "",
        cpf: "",
        cpf_error: "",
        matrícula: "",
        matrícula_error: "",
        email: "",
        email_error: "",
        crm: "",
        crm_error: "",
        cbo: "",
        cbo_error: "",
        phone: "",
        phone_error: "",
        userType: "",
        state: "",
        usertype_error: "",
        regional_code: "",
        date_error: "",
      },
      profile: "",
      options: ["Cadastrar clínica", "Clínica A", "Clínica B", "Clínica C"],
      selected: "Campo alterado",
      show: true,
      errorMsg: "",
      clinicList: [],
      showDetail: false,
      userData: new FormData(),
      getUserId: "",
      createdBy: "",
      loader: false,
      isProfileErrorShow: false,
      clinicLoader: false,
      selectOptions: [
        { value: "admin", text: "Usuário administrador" },
        { value: "staff", text: "Usuário da equipe" },
      ],
      stateList: [],
    };
  },
  watch: {
  },
  mounted() {
    if (this.$route.name == "EditStaff") {
      if (localStorage.getItem("editUser") != null) {
        this.userData = JSON.parse(localStorage.getItem("editUser"));
        this.getUserId = this.userData.id;
        this.form.name = this.userData.name;
        this.form.email = this.userData.email;
        this.form.birthdate = this.userData.birthdate;
        // this.form.birthdate = moment(this.userData.birthdate).format(
        //   "YYYY-MM-DD"
        // );
        console.log("🚀 ~ file: AddStaff.vue ~ line 670 ~ mounted ~ this.userData", this.userData)

        this.form.cpf = this.userData.cpf;
        this.form.crm = this.userData.crm;
        this.form.cbo = this.userData.cbo;
        this.form.regional_code = this.userData.regional_code;
        this.form.Estado = this.userData.stateData[0];
        this.form.state = JSON.parse(JSON.stringify(this.userData.stateData))
        this.form.state = this.form.state[0] ? this.form.state[0].state : ''
        this.form.phone = this.userData.telephone;
        if (this.userData.clinicData.length > 0) {
          if (
            this.userData.roles &&
            this.userData.roles.indexOf("admin") != -1
          ) {
            this.form.selected = [];
          } else {
            this.form.selected = this.userData.clinicData;
          }
        }

        this.profile = this.userData.avatar;
        if (this.userData.roles && this.userData.roles.indexOf("admin") != -1) {
          this.form.userType = "Usuário administrador";
        } else {
          this.form.userType = "Usuário da equipe";
        }
        // this.createdBy = this.userData.createdById;
      }
    }

    this.getClinicList();
    this.getStateList()
  },
  computed: {
    mask() {
      let tele = Number(this.form.phone);
      return tele.length == 14 ? "(##) ####-####" : "(##) #####-####";
    },
    clinic_mask() {
      let tele = Number(this.ClinicalForm.telephone);
      return tele.length == 14 ? "(##) ####-####" : "(##) #####-####";
    },
  },
  methods: {
    onContext(ctx) {
      if (ctx.selectedYMD) {
        let tempDate = ctx.selectedYMD.split("-");
        this.fomateData = tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
        this.form.date_error = "";
      }
    },
    convertToDate(date) {
      if (!date.includes("-")) {
        if (date != "") {
          let day = date.substring(0, 2);
          let month = date.substring(2, 4);
          let year = date.substring(4, 8);
          let updatedBirthdate = year + "-" + month + "-" + day;
          return new Date(updatedBirthdate);
        }
        return new Date(date);
      }
      let splitDate = date.split("-");
      let updatedBirthdate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
      return new Date(updatedBirthdate);
    },
    handleInput(e) {
      let newDateMoment = moment(this.convertToDate(e.target.value)).utc().format(
        "YYYY-MM-DD"
      )
      newDateMoment = new Date(newDateMoment);
      let currentDay = new Date();
      currentDay = new Date(currentDay);
      if (newDateMoment.getTime() > currentDay.getTime()) {
        this.form.date_error = "por favor insira um comprimento válido";
      } else {
        if (e.target.value.length < 8) {
          this.form.date_error =
            "por favor insira um comprimento de data válido";
        } else {
          if (!e.target.value.includes("-")) {
            if (e.target.value != "") {
              let updatedBirthdate = this.convertToDate(e.target.value);
              if (!isNaN(updatedBirthdate.getTime())) {
                this.form.birthdate =
                  moment(updatedBirthdate).utc().format("YYYY-MM-DD");
                this.fomateData = moment(updatedBirthdate).utc().format("YYYY-MM-DD")
                this.form.date_error = "";
              } else {
                this.form.date_error = "Por favor, insira uma data válida";
              }
            }
          } else {
          let updatedBirthdate = this.convertToDate(e.target.value);
          if(!isNaN(updatedBirthdate.getTime())) {
              this.form.birthdate = moment(updatedBirthdate).utc().format("YYYY-MM-DD");
              this.form.date_error = "";
          } else {
            this.form.date_error = "Por favor, insira uma data válida";
            }
          }
        }
      }
    },
    getOptions() {
      if (
        this.form.userType == "Usuário administrador" ||
        this.form.userType.value == "admin"
      ) {
        this.form.selected = [];
      }
    },
    changeTabs: function (tabName) {
      // if (!this.Validate()) {
      if (tabName == "tab-1") {
        this.showDetail = false;
      } else {
        this.showDetail = true;
      }
      // }
    },
    fileSelect: function () {
      if (
        this.$refs.file.files.length > 0 &&
        this.$refs.file.files[0].type.includes("image/")
      ) {
        this.profile = URL.createObjectURL(this.$refs.file.files[0]);
      } else {
        this.isProfileErrorShow = true;
      }
    },
    validateClinic() {
      var error = false;
      this.ClinicalForm.email_error = "";
      this.ClinicalForm.name_error = "";
      this.ClinicalForm.cnpj_error = "";
      this.ClinicalForm.responsibleName_error = "";
      this.ClinicalForm.telephone_error = "";

      if (!this.ClinicalForm.name || this.ClinicalForm.name.trim() == "") {
        this.ClinicalForm.name_error = "Por favor insira o nome";
        error = true;
      }
      if (!this.ClinicalForm.cnpj || this.ClinicalForm.cnpj.trim() == "") {
        this.ClinicalForm.cnpj_error = "Por favor insira CNPJ";
        error = true;
      }
      if (
        this.ClinicalForm.cnpj &&
        !this.validateCnpj(this.ClinicalForm.cnpj)
      ) {
        this.ClinicalForm.cnpj_error = "Inválido CNPJ";
        error = true;
      }
      if (!this.ClinicalForm.email || this.ClinicalForm.email.trim() == "") {
        this.ClinicalForm.email_error = "Por favor insira o email";
        error = true;
      }

      if (!this.validateEmail(this.ClinicalForm.email)) {
        this.ClinicalForm.email_error = "Por favor insira um email válido";
        error = true;
      }
      if (
        !this.ClinicalForm.responsibleName ||
        this.ClinicalForm.responsibleName.trim() == ""
      ) {
        this.ClinicalForm.responsibleName_error =
          "Por favor, insira o nome do responsável";
        error = true;
      }
      if (
        !this.ClinicalForm.telephone ||
        this.ClinicalForm.telephone.trim() == ""
      ) {
        this.ClinicalForm.telephone_error =
          "Por favor insira o número do telefone";
        error = true;
      }

      return error;
    },

    resetClinic() {
      this.$bvModal.hide("modal-center");
      this.ClinicalForm = {
        email: "",
        email_error: "",
        name: "",
        name_error: "",
        cnpj: "",
        cnpj_error: "",
        responsibleName: "",
        responsibleName_error: "",
        telephone: "",
        telephone_error: "",
      };
      this.clinicLoader = false;
    },
    addClinic(event) {
      event.target.disabled = true;
      this.clinicLoader = true;
      if (!this.validateClinic()) {
        let token = localStorage.getItem("token");
        let obj = {
          email_of_head: this.ClinicalForm.email,
          name: this.ClinicalForm.name,
          cnpj: this.ClinicalForm.cnpj,
          name_of_head_clinic: this.ClinicalForm.responsibleName,
          phone: this.ClinicalForm.telephone,
        };

        axios
          .post(process.env.VUE_APP_API_URL + "/clinic/addClinic", obj, {
            headers: {
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              this.clinicLoader = false;
              this.$bvModal.hide("modal-center");
              this.ClinicalForm = {};
              this.getClinicList();
              event.target.disabled = false;
            } else {
              console.log("else");
              // error Alert
            }
          }).catch((e) => {
            this.errorHandle(e.response);
          });
        this.clinicLoader = false;
      }
    },
    async getClinicList() {
      try {
        this.loader = true;
        let result = await axios.get(
          process.env.VUE_APP_API_URL + "/clinic/getClinic",
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );
        if (result.status == 200) {
           this.loader= false;
            this.getStateList();
          if (result && result.data && result.data.data) {
            this.clinicList = result.data.data;
            console.log(this.clinicList);
          } else {
            this.clinicList = [];
          }
        }
      } catch (e) {
        this.loader = false;
        this.errorMsg = e.response.data.message;
        this.errorHandle(e.response);
      }
    },
    async getStateList() {
      try {
        let result = await axios.get(
          process.env.VUE_APP_API_URL + "/user/state-details",
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }  
        );
        if (result.status == 200) {
          if (result && result.data && result.data.result) {
            this.stateList = result.data.result;
          } else {
            this.stateList = [];
          }
        }
      } catch (e) {
        this.errorMsg = e.response.data.message;
        this.errorHandle(e.response);
      }
    },
    backToList() {
      this.showDetail = false;
      this.form = {
        name: "",
        birthdate: "",
        selected: "",
        Estado: "",
        cpf: "",
        matrícula: "",
        email: "",
        crm: "",
        cbo: "",
        phone: "",
      };
      this.userData = new FormData();
      localStorage.removeItem("userData");
      this.$router.push("/home");
    },
    back() {
      this.showDetail = false;
    },

    save() {
      try {
        if (!this.Validate()) {
          // Convert JSON into Form-Data
          this.userData = new FormData();
          this.userData.append(
            "file",
            this.profile ? this.$refs.file.files[0] : ""
            );

            this.form.state = this.form.Estado.state
          this.userData.append("name", this.form.name);
          this.userData.append("email", this.form.email);
          this.userData.append("birthdate", this.form.birthdate);
          this.userData.append("state_code", this.form.Estado.state_code);
          this.userData.append("regional_code", this.form.regional_code);
          this.userData.append("cpf", this.form.cpf);
          // this.userData.append("matrícula", this.form.matrícula);
          let clinic = [];

          
          if (
            this.form.userType.value != "admin" ||
            this.form.userType != "Usuário administrador"
          ) {
            if(this.addAllClinc) {
              this.form.selected = this.clinicList;
              this.selected_clinic = this.form.selected.map((ele) => ele.name);
              this.selected_clinic = this.selected_clinic.join();
              clinic = this.clinicList.map((ele) => ele._id);
            } else if (typeof this.form.selected != "string") {
              clinic = this.form.selected.map((ele) => ele._id);
              this.selected_clinic = this.form.selected.map((ele) => ele.name);
              this.selected_clinic = this.selected_clinic.join();
            } else {
              clinic = [this.form.selected];
            }
            this.userData.append(
              "clinic",
              this.form.selected && this.form.selected.length > 0 ? clinic : []
            );
          } else {
            this.userData.append("clinic", []);
          }
          this.userData.append("id", this.getUserId ? this.getUserId : "");
          this.userData.append("phone", this.form.phone ? this.form.phone : "");
          this.userData.append("cbo", "");
          this.userData.append("crm", "");
          this.userData.append("host", window.location.hostname);
          if (this.form.userType.value) {
            this.userData.append("role", this.form.userType.value);
          }
          //   this.userData = obj

          // let temp = this.userData
          localStorage.setItem("userData", JSON.stringify(this.userData));
          this.showDetail = true;
        } else {
          console.log("invalid data");
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addStaff() {
      try {
        this.loader = true;
        let token = localStorage.getItem("token");
        let result = [];
        this.profile;
        if (this.$route.name != "EditStaff") {
          result = await axios.post(
            process.env.VUE_APP_API_URL + "/user/add-staff",
            this.userData,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
        } else {
          result = await axios.post(
            process.env.VUE_APP_API_URL + "/user/edit-staff",
            this.userData,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
        }

        if (result.status == 200) {
          this.loader = false;
          this.resetValue();
          this.backToList();
          notification("Cadastro realizado com sucesso", "success");
        }
        // else{
        //   notification("Ocorreu um erro ao alterar a interação!",'error')
        // }
      } catch (e) {
        console.log(e.response);
        this.loader = false;
        this.errorHandle(e.response);
      }
    },
    resetValue() {
      // this.form = {},
      // (this.birthdate = ""),
      (this.selected = []),
        (this.cpf = ""),
        (this.matrícula = ""),
        (this.email = ""),
        (this.crm = ""),
        (this.cbo = ""),
        (this.phone = "");
      this.userType = "";
    },
    Validate() {
      var error = false;
      this.form.name_error = "";
      this.form.cpf_error = "";
      this.form.matrícula_error = "";
      this.form.birthdate_error = "";
      this.form.email_error = "";
      this.form.cbo_error = "";
      this.form.crm_error = "";
      this.form.selected_error = "";
      this.form.usertype_error = "";
      this.form.Estado_error = "";
      this.form.regional_code_error = "";
      const pattern = /\d+/g;
      if (!this.form.name || this.form.name.trim() == "") {
        this.form.name_error = "Por favor insira o nome";
        error = true;
      }
      if(this.form.Estado.state_code == '') {
        this.form.Estado_error = "Por favor, insira o UF"
      } 
      this.form.state = this.form.Estado.state
      // else if (JSON.parse(JSON.stringify(this.form.Estado.status_code)) == "") {
      //   this.form.Estado_error = "Por favor, insira o UF"
      // }

      if (!this.form.cpf || this.form.cpf.trim() == "") {
        this.form.cpf_error = "Por favor, insira o CPF";
        error = true;
      } else if (!this.ValidateCPF(this.form.cpf)) {
        this.form.cpf_error = "Por favor, insira um CPF válido";
        error = true;
      }
      // if (!this.form.matrícula) {
      //   this.form.matrícula_error = "Por favor, insira o matrícula";
      //   error = true;
      // } else if (!this.ValidateMatrícula(this.form.matrícula)) {
      //   this.form.matrícula_error = "Por favor, insira um matrícula válido";
      //   error = true;
      // }
      if (!this.form.birthdate || this.form.birthdate.trim() == "") {
        this.form.birthdate_error = "Por favor, insira a data de nascimento";
        error = true;
      }
      if (!this.form.email || this.form.email.trim() == "") {
        this.form.email_error = "Por favor insira o email";
        error = true;
      }
      if (!this.validateEmail(this.form.email)) {
        this.form.email_error = "Por favor insira um email válido";
        error = true;
      }
      // if (!this.form.cbo || this.form.cbo == "") {
      //   this.form.cbo_error = "Por favor, insira CBO";
      //   error = true;
      // }
      if (
        this.form.cbo &&
        this.form.cbo != "" &&
        !pattern.test(parseInt(this.form.cbo))
      ) {
        this.form.cbo_error = "Inválidos CBO";
        error = true;
      }
      if (
        this.form.regional_code &&
        this.form.regional_code != "" &&
        !this.checkCRM(this.form.regional_code)
      ) {
        this.form.regional_code_error = "Inválidos Regional Code";
        error = true;
      } else {
        this.form.regional_code_error = "";
      }

      if(this.addAllClinc) {
        this.form.selected = this.clinicList;
      } else if(!this.addAllClinc && !this.form.userType) {
        this.form.selected_error = "Selecione o estabelecimento";
        error = true;
      }
      if (
        (this.form.userType == "Usuário da equipe" ||
          this.form.userType.value == "staff") &&
        this.form.selected &&
        this.form.selected.length < 1
      ) {

        
        this.form.selected_error = "Selecione o estabelecimento";
        error = true;
      }
      if (!this.form.userType) {
        this.form.usertype_error = "Selecione o tipo de usuário";
        error = true;
      }
      // else {
      //   error = false;
      // }

      return error;
    },
    checkCRM(crm) {
      var Exp = /^[0-9a-zA-Z]+$/;
      if (!crm.match(Exp)) {
        return false;
      }
      return true;
    },
    ValidateMatrícula(val) {
      if (val.length == 6) {
        return true;
      }
      return false;
    },
    ValidateCPF(val) {
      if (val.length == 14) {
        var cpf = val.trim();

        cpf = cpf.replace(/\./g, "");
        cpf = cpf.replace("-", "");
        cpf = cpf.split("");

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (var k = 0, p2 = 10; cpf.length - 2 > k; k++, p2--) {
          v1 += cpf[k] * p2;
        }

        v1 = (v1 * 10) % 11;

        if (v1 == 10) {
          v1 = 0;
        }

        if (v1 != cpf[9]) {
          return false;
        }

        for (var j = 0, p1 = 11; cpf.length - 1 > j; j++, p1--) {
          v2 += cpf[j] * p1;
        }

        v2 = (v2 * 10) % 11;

        if (v2 == 10) {
          v2 = 0;
        }

        if (v2 != cpf[10]) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validateCnpj(val) {
      if (val.length == 18) {
        var cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, "");
        cnpj = cnpj.replace("-", "");
        cnpj = cnpj.replace("/", "");
        cnpj = cnpj.split("");

        var v3 = 0;
        var v4 = 0;
        var aux1 = false;

        for (var l = 1; cnpj.length > l; l++) {
          if (cnpj[l - 1] != cnpj[l]) {
            aux1 = true;
          }
        }

        if (aux1 == false) {
          return false;
        }

        for (var m = 0, p5 = 5, p6 = 13; cnpj.length - 2 > m; m++, p5--, p6--) {
          if (p5 >= 2) {
            v3 += cnpj[m] * p5;
          } else {
            v3 += cnpj[m] * p6;
          }
        }

        v3 = v3 % 11;

        if (v3 < 2) {
          v3 = 0;
        } else {
          v3 = 11 - v3;
        }

        if (v3 != cnpj[12]) {
          return false;
        }

        for (var k = 0, p3 = 6, p4 = 14; cnpj.length - 1 > k; k++, p3--, p4--) {
          if (p3 >= 2) {
            v4 += cnpj[k] * p3;
          } else {
            v4 += cnpj[k] * p4;
          }
        }

        v4 = v4 % 11;

        if (v4 < 2) {
          v4 = 0;
        } else {
          v4 = 11 - v4;
        }

        if (v4 != cnpj[13]) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validateEmail(email) {
      var emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        return true;
      } else {
        return false;
      }
    },

    check_validation(event) {
      const pattern = /\d+/g;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    },

    checkCNPJ() {
      var letters = /^[a-zA-Z]*$/g;
      if (letters.test(this.ClinicalForm.cnpj) == true) {
        console.log("IN", letters.test(this.ClinicalForm.cnpj));
        event.preventDefault();
        this.ClinicalForm.cnpj = "";
      }
    },
    pasteCNPJ(e) {
      console.log(e);
      // cancel paste
      if (e.button == 2) {
        e.preventDefault();
      }
    },
  },
  created() {
    this.resetValue();
  },
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.circleimage {
  border-radius: 50%;
}

.add-clinic {
  max-height: 41px !important;
}

.b-calendar .b-calendar-inner {
  min-width: 370px !important;
}

.max-height {
  max-height: 50px !important;
}

.one-long-line {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.one-long-line:hover {
  overflow: visible;
}

.right-selectdiv .v-select ul {
  min-width: 100%;
}

.twocolumn.none-backimg .vs__dropdown-toggle {
  background-image: none !important;
  padding-left: 0 !important;
}

.twocolumn.none-backimg .col-8 .input-group input.form-control {
  border-left: var(--blackcolor) solid 1px;
  min-height: 40px;
  border-radius: 5px;
}

.addstaff .formdiv .form-group label {
  font-size: 15px;
}

.multiselect .multiselect__tags{
  border-color:var(--blackcolor);
}
.multiselect .multiselect__tags .multiselect__placeholder{
  margin-bottom: 0;
    padding-top: 0;
    font-size: 15px;
    padding-left: 8px;
    color : #848080;
}
.multiselect .multiselect__tag{
  background: var(--darkgreen);
}
.multiselect .multiselect__content-wrapper{
 border-color:var(--blackcolor);
}
.multiselect .multiselect__content-wrapper .multiselect__content li span.multiselect__option--highlight{
    background-color: var(--darkgreen);
}
.multiselect .multiselect__content-wrapper .multiselect__content li span.multiselect__option--highlight:after{
    background-color: var(--darkgreen);
}

</style>
