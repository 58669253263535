<template>
  <div class="loginarea">
    <b-container>
      <b-row>
        <b-col
          md="5"
          class="d-none text-right d-md-flex align-items-center justify-content-end"
        >
          <!-- <b-img src="" alt=""></b-img> -->
          <img
            class="loginvector ml-auto"
            src="../assets/images/login-vector.svg"
          />
        </b-col>
        <b-col md="7">
          <div class="mainbox-form">
            <div class="topheading mb-5">
              <h2 class="heading">Bem-vindo(a)</h2>
              <label class="subheading"
                >Cadastro e relatórios de novos usuários</label
              >
            </div>
            <b-form v-if="show">
              <b-form-group id="input-group-1">
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="email"
                  placeholder="E-mail"
                ></b-form-input>
                <label class="subtext">Digite o e-mail</label>
              </b-form-group>

              <b-form-group id="input-group-2">
                <b-form-input
                  :type="type"
                  placeholder="Senha"
                  v-model="password"
                  id="password"
                ></b-form-input>
                <!-- v-on:keydown="buttonClick()" -->
                <template v-if="type == 'password'">
                  <img
                    src="../assets/images/eye.svg"
                    class="showpassword"
                    alt="password"
                    @click="showPassword"
                  />
                </template>
                <template v-if="type != 'password'">
                <img
                  src="../assets/images/eye-hide.svg"
                  class="showpassword"
                  alt="password"
                  @click="showPassword"
                />
                </template>
                <label class="subtext">Digite sua senha</label>
              </b-form-group>


              <p style="background-color: red;"> {{ errorMsg }}</p>
              <b-form-group>
              <b-button
                pill
                block
                class="w-100 intrarbutton"
                type="button"
                variant="primary"
                @click="buttonClick()"
                :disabled="!isValidEmail || !isValidPassword || loader"
                >Entrar</b-button
              ><span class="loader" v-if="loader"></span>
              </b-form-group>
              <label class="passwordbott" pill id="passwordlink" @click="changeRoute()">
                Esqueci minha senha</label
              >
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import commonMixin from '../utils/commonMixin'
import { notification as notificationToast } from "../utils/customToast";
let self


export default Vue.extend({
  name: "Login",
  
  mixins: [commonMixin],
  components: {
  },
  data() {
    return {
      page_title: "Login - CEMIG",
      errorMsg: '',
      email: "",
      password: "",
      isValidEmail:false,
      isValidPassword:false,
      show: true,
      type: "password",
      loader: false
    };
  },
  watch:{
    email : function (value) {
      this.isValidEmail = this.checkEmail(value);
    },
    password : function (value) {
      this.isValidPassword = this.checkPassword(value);
    }
  },
  
  mounted() {
    self = this;

    // notificationToast('MY MESSG1','success')
    // notificationToast('MY MESSG2','warning')
    // notificationToast('MY MESSG3','error')
    if(localStorage.getItem('token') != null) {
      if(localStorage.getItem('userObj') != null) {

        var userObj = JSON.parse(localStorage.getItem('userObj'));
        if(userObj.is_first_login == true) {
          this.$router.push("/reset-password");
        } else {
          this.$router.push("/home");
        }

      }

      // this.$router.push("/home");
    }

  },
  methods: {
    changeRoute: function(){
      this.$router.push("/forget-password");
    },
    checkEmail: function(value){
       var emailRegex = /\S+@\S+\.\S+/;
       var hasWhiteSpace = /\s/g;
       if(emailRegex.test(value) && !hasWhiteSpace.test(value)){
         return true;
       }
    },
    checkPassword: function(value){
      if(value.length > 0){
         return true;
      }
    },
    buttonClick: function () {
      self = this
      self.errorMsg = ""
      this.loader = true
      axios.post(process.env.VUE_APP_API_URL + "/login", {
            email:  this.email, 
            password: this.password
          })
          .then(response => {
            if(response.status == 200) {
              this.loader =false
              localStorage.setItem('userObj', JSON.stringify(response.data))
              localStorage.setItem('token', response.data.token)
              // notificationToast("",'success')
              if(response.data.is_first_login == true) {
                this.$router.push("/reset-password");
              } else {
                this.$router.push("/home");
              }
              
            } else {
              // error Alert
              notificationToast(response.data.message,'error')
            }
          })
          .catch(function (error) {
            self.loader =false
            if (error.response) {
              notificationToast(error.response.data.message,'error')
            } else {
              notificationToast(error.response.data.message,'error')
            }
          })
      
    },
     showPassword: function () {
       if(this.type === "password") {
          this.type = "text"
       } else {
          this.type = "password"
       }
     }
  },
});
</script>
