<template>
  <div class="historydiv">
    <b-container>
      <b-row>
        <div class="middle-fixed">
          <h3 class="tilediv">Histórico de alterações</h3>

          <b-form class="formdiv">
            <b-row>
              <b-col cols="12" md="5" class="custom-col1">
                <b-form-group>
                  <div class="input-group history-input">
                    <b-input-group>
                      <b-form-input
                        v-model="startfomateData"
                        id="startDate"
                        type="text"
                        placeholder="DD-MM-YYYY"
                        autocomplete="off"
                        @blur="handleInput($event)"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          id="startDatePicker"
                          v-model="startDate"
                          button-only
                          right
                          locale="en-US"
                          :max="new Date()"
                          @context="startDateContext"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                    <div class="d-flex flex-row">
                      <div class="validation-message" v-if="startDate_error">
                        {{ startDate_error }}
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2" class="custom-col3">
                <span> Até</span>
              </b-col>
              <b-col cols="12" md="5" class="custom-col2">
                <b-form-group>
                  <div class="input-group history-input">
                    <b-input-group class="mb-3">
                      <b-form-input
                        v-model="endformatData"
                        id="endDate"
                        type="text"
                        placeholder="DD-MM-YYYY"
                        autocomplete="off"
                        @blur="handleInput($event)"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          id="endDatePicker"
                          v-model="endDate"
                          button-only
                          right
                          locale="en-US"
                          :max="new Date()"
                          @context="endDateContext"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="endDate_error" class="validation-message">
                      {{ endDate_error }}
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label="Responsável"
                  ><span v-if="searchLoad" class="loader"></span>
                  <div class="input-group line-form">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          src="../assets/images/usericon.svg"
                          alt=""
                          class="searchicon"
                        />
                      </div>
                    </div>
                    <b-form-input
                      v-model="updatedName"
                      placeholder="Luis Romero"
                      @keyup="searchUser('updated')"
                      v-on:keydown.enter.prevent=""
                    ></b-form-input>
                    <ul
                      v-if="updatedUserList && updatedName"
                      class="list-unstyled list-result m-0 p-0 w-100 list-search"
                    >
                      <li
                        :id="item.id"
                        :class="[
                          'text-capitalize pointer p-3 custom-list',
                          updateUserID == item._id ? 'text-green selected' : '',
                        ]"
                        v-for="item in updatedUserList"
                        :key="item._id"
                        @click="selectUser(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </b-form-group>

                <b-form-group label="Proprietário da conta"
                  ><span v-if="searchUserLoad" class="loader"></span>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          src="../assets/images/usericon.svg"
                          alt=""
                          class="searchicon"
                        />
                      </div>
                    </div>
                    <b-form-input
                      v-model="userName"
                      placeholder="Marcela Pereira"
                      @keyup="searchUser('owner')"
                      v-on:keydown.enter.prevent=""
                    ></b-form-input>
                    <ul
                      v-if="userList && userName"
                      class="list-unstyled list-result m-0 p-0 bg-white w-100 list-search"
                    >
                      <li
                        :id="item.id"
                        :class="[
                          'text-capitalize pointer p-3 custom-list',
                          userID == item._id ? 'text-green selected' : '',
                        ]"
                        v-for="item in userList"
                        :key="item._id"
                        @click="selectOwner(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </b-form-group>

                <b-form-group label="Campo alterado">
                  <v-select
                    class="style-chooser"
                    v-model="field"
                    :options="fieldList"
                    placeholder="Selecione o campo"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Clínica do responsável">
                  <v-select
                    class="style-chooser"
                    v-model="updateClinic"
                    :options="clinicList"
                    placeholder="Selecione a Clínica"
                    label="name"
                  />
                </b-form-group>
                <b-form-group label="Clínica da conta">
                  <v-select
                    class="style-chooser"
                    v-model="clinic"
                    placeholder="Selecione a Clínica"
                    :options="clinicList"
                    label="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="mb-5 mt-4">
              <div class="d-flex justify-content-center">
                <div class="w-50 position-relative">
                  <b-button
                    pill
                    variant="success"
                    :disabled="searchUserLoad || searchLoad"
                    class="btn-sm pesquisar"
                    @click="filterHistroy(1)"
                    ><img
                      src="../assets/images/search-icon1.svg"
                      class="searchicon"
                      alt=""
                    />Pesquisar</b-button
                  ><span
                    class="loader history-loader"
                    v-if="load || getLoad"
                  ></span>
                </div>
              </div>
            </b-form-group>
          </b-form>

          <div class="table-responsive mb-2 px-0">
            <table
              class="table table-hover table-striped"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Hora</th>
                  <th>Responsável</th>
                  <th>Campo</th>
                  <!-- <th>Código</th> -->
                  <th>Proprietário da conta</th>
                  <th>Valor anterior</th>
                  <th>Valor alterado</th>
                </tr>
              </thead>

              <tbody>
                <tr v-if="historyLogs.length <= 0">
                  <td colspan="8" class="text-center background-none">
                    Não há registro
                  </td>
                </tr>
                <tr
                  class="list-td"
                  v-for="history in historyLogs"
                  :key="history._id"
                >
                  <td>{{ showDate(history.createdAt) }}</td>
                  <td>{{ showTime(history.createdAt) }}</td>
                  <td>{{ history.UpdatedName }}</td>
                  <template>
                    <template v-if="history.field == 'state_code'">
                      <td>Código do estado</td>
                    </template>
                    <template v-else-if="history.field == 'regional_code'">
                      <td>Código regional</td>
                    </template>
                    <template v-else>
                      <td>{{ history.field }}</td>
                    </template>
                  </template>
                  <td>{{ history.UserName }}</td>
                  <td>{{ history.oldValue }}</td>
                  <td>{{ history.newValue }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="historyLogs.length && total > 5"
            class="pagination-block text-center my-1"
          >
            <paginate
              :value="current_page"
              :page-count="Math.ceil(total / limit)"
              :page-range="1"
              :click-handler="onChangePage"
              :prev-text="''"
              :next-text="''"
              :container-class="'pagination justify-content-center m-0'"
              :page-class="'page-item'"
            ></paginate>
          </div>
          <b-form-group v-if="historyLogs.length != 0" class="mt-4">
            <b-button
              pill
              variant="warning"
              class="btn-sm download"
              @click="downLoadData"
              :disabled="downLoader"
              ><img
                src="../assets/images/download-icon.svg"
                class="searchicon"
                alt=""
              />Download</b-button
            >
          </b-form-group>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import commonMixin from "../utils/commonMixin";
const moment = require("moment");
const debounce = require("lodash/debounce");
import { notification } from "../utils/customToast";
export default {
  NAME: "HistoryContainer",
  mixins: [commonMixin],
  data() {
    return {
      page_title: "Staff History - CEMIG",
      options: ["Cadastrar clínica", "Clínica A", "Clínica B", "Clínica C"],
      selected: "Campo alterado",
      startDate: "",
      startDate_error: "",
      endDate_error: "",
      startfomateData: moment().format("DD-MM-YYYY"),
      endformatData: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
      current_page: 1,
      limit: 10,
      total: 0,
      updatedName: "",
      userName: "",
      field: "",
      updateClinic: "",
      clinic: "",
      downLoader: false,
      show: true,
      load: false,
      historyLogs: [],
      clinicList: [],
      fieldList: [],
      getLoad: false,
      updatedUserList: [],
      userList: [],
      searchLoad: false,
      getId: "",
      updateUserID: "",
      userID: "",
      searchUserLoad: false,
      searchObj: {},
      resetEnddate: null,
      tempPg: null,
    };
  },
  methods: {
    convertToDate(date) {
      if (!date.includes("-")) {
        if (date != "") {
          let day = date.substring(0, 2);
          let month = date.substring(2, 4);
          let year = date.substring(4, 8);
          let updatedDate = year + "-" + month + "-" + day;
          return updatedDate;
        }
        return date;
      } else {
        let day = date.substring(0, 2);
        let month = date.substring(3, 5);
        let year = date.substring(6, 10);
        let updatedDate = year + "-" + month + "-" + day;
        return updatedDate;
      }
      // return date;
    },
    startDateContext(ctx) {
      if (ctx.selectedYMD) {
        if (this.endformatData != "") {
          let endDate = this.convertToDate(this.endformatData);
          if (
            new Date(ctx.selectedYMD).getTime() > new Date(endDate).getTime() &&
            new Date(ctx.selectedYMD).getTime() != new Date(endDate).getTime()
          ) {
            this.startDate_error = "Invalid Date";
          } else {
            let tempDate = ctx.selectedYMD.split("-");
            this.startfomateData =
              tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
            this.startDate_error = "";
            console.log("YOOOO", this.startfomateData);
            this.startDate = this.startfomateData;
            console.log("SET -2 ", this.startDate);
          }
        } else {
          let tempDate = ctx.selectedYMD.split("-");
          this.startfomateData =
            tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
          this.startDate = new Date(ctx.selectedYMD);
          console.log("SET -1 ", this.startDate);

          this.startDate_error = "";
        }
      }
    },
    endDateContext(ctx) {
      if (ctx.selectedYMD) {
        if (this.startfomateData != "") {
          let startDate = this.convertToDate(this.startfomateData);
          if (
            new Date(ctx.selectedYMD).getTime() <
              new Date(startDate).getTime() ||
            new Date(ctx.selectedYMD).getTime() > new Date().getTime()
          ) {
            this.endDate_error = "Invalid Date";
          } else {
            let tempDate = ctx.selectedYMD.split("-");
            this.endformatData =
              tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
            this.endDate_error = "";
          }
        } else {
          let tempDate = ctx.selectedYMD.split("-");
          this.endformatData =
            tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
          this.endDate_error = "";
        }
      }
    },
    handleInput(e) {
      let dateId = e.target.id;
      let newDateMoment = moment(this.convertToDate(e.target.value)).format(
        "YYYY-MM-DD"
      );
      newDateMoment = new Date(newDateMoment);
      if (moment(this.convertToDate(e.target.value)).isValid()) {
        let currentDay = new Date();
        currentDay = new Date(currentDay);
        if (newDateMoment.getTime() > currentDay.getTime()) {
          if (dateId === "startDate") {
            this.startDate_error = "por favor insira um comprimento válido";
          }
          if (dateId === "endDate") {
            this.endDate_error = "por favor insira um comprimento válido";
          }
        } else {
          if (e.target.value.length < 8) {
            if (dateId === "startDate") {
              this.startDate_error = "por favor insira um comprimento válido";
            }
            if (dateId === "endDate") {
              this.endDate_error = "por favor insira um comprimento válido";
            }
          } else {
            let updatedDate = this.convertToDate(e.target.value);
            console.log(updatedDate, dateId);

            if (dateId === "startDate") {
              this.startDate = moment(updatedDate).format("DD-MM-YYYY");
              this.startfomateData = moment(updatedDate).format("DD-MM-YYYY");
              this.startDate_error = "";
            }
            if (dateId === "endDate") {
              if (moment(updatedDate).format("DD-MM-YYYY") < this.startDate) {
                this.endDate_error = "data de término inválida";
              } else {
                this.endDate = moment(updatedDate).format("DD-MM-YYYY");
                this.endformatData = moment(updatedDate).format("DD-MM-YYYY");
                this.endDate_error = "";
              }
            }
          }
        }
      } else {
        if (dateId === "startDate") {
          this.startDate_error = "data de término inválida";
        }
        if (dateId === "endDate") {
          this.endDate_error = "por favor insira um comprimento válido";
        }
      }
    },
    async downLoadData() {
      try {
        this.searchObj = {};
        if (this.updateUserID) {
          this.searchObj.updatedBy = this.updateUserID;
        }
        if (this.userID) {
          this.searchObj.user = this.userID;
        }
        if (this.updateClinic) {
          this.searchObj.clinicOfUpdate = this.updateClinic._id;
        }
        if (this.clinic) {
          this.searchObj.clinicUser = this.clinic._id;
        }
        if (this.field) {
          this.searchObj.field = this.field.id;
        }
        if (this.startDate) {
          let temp = this.convertToDate(this.startDate);
          this.searchObj.startDate = moment(new Date(temp)).format(
            "YYYY-MM-DD"
          );
        }
        if (this.startfomateData) {
          let temp = this.convertToDate(this.startfomateData);
          this.searchObj.startDate = moment(new Date(temp)).format(
            "YYYY-MM-DD"
          );
        }
        if (this.endDate) {
          let temp = this.convertToDate(this.endDate);
          this.searchObj.endDate = moment(new Date(temp)).format("YYYY-MM-DD");
        }
        if (this.endformatData) {
          let temp = this.convertToDate(this.endformatData);
          this.searchObj.endDate = moment(new Date(temp)).format("YYYY-MM-DD");
        }

        this.downLoader = true;
        let result = await axios.post(
          process.env.VUE_APP_API_URL + "/user/download-history",
          this.searchObj
        );
        if (result.status == 200) {
          this.downLoader = false;
          if (result && result.data && result.data.batch) {
            let batchno = result.data.batch;

            notification("Download de dados em andamento!", "success");

            localStorage.setItem("batch-export", batchno);
            setTimeout(() => {
              this.checkDownloadBatchStatus(batchno);
            }, 10000);
          } else {
            this.downLoader = false;
          }
        } else {
          return;
        }
      } catch (e) {
        // console.log(e);
        this.downLoader = false;
        this.errorHandle(e.response);
      }
    },
    async checkDownloadBatchStatus(batchId) {
      //console.log("BATCH CALL FOR STATUS ===== ", batchId);
      let results = await axios.get(
        process.env.VUE_APP_API_URL + `/user/get-download-status/${batchId}`
      );
      if (results.status === 200) {
        if (results.data.payload == "completed") {
          localStorage.removeItem("batch-export");
          this.downloadLoad = false;
          var fileURL = results.data.link;
          window.open(fileURL, "_blank");
          // var fileLink = document.createElement("a");

          // fileLink.href = fileURL;
          // document.body.appendChild(fileLink);

          // fileLink.click();
          notification("Download do arquivo com sucesso!", "success");
        } else if (results.data.payload == "failed") {
          localStorage.removeItem("batch-export");
          this.downloadLoad = false;
        } else if (results.data.payload == "no data") {
          localStorage.removeItem("batch-export");
          this.downloadLoad = false;
        } else {
          setTimeout(() => {
            this.checkDownloadBatchStatus(batchId);
          }, 10000);
        }
      }
    },
    async filterHistroy(page) {
      try {
        this.current_page = page;
        this.searchObj = {
          page,
          limit: this.limit,
          is_pagination: "true",
        };

        if (this.updateUserID) {
          this.searchObj.updatedBy = this.updateUserID;
        }
        if (this.userID) {
          if (this.userName) {
            this.searchObj.user = this.userID;
          }
        }
        if (this.updateClinic) {
          this.searchObj.clinicOfUpdate = this.updateClinic._id;
        }
        if (this.clinic) {
          this.searchObj.clinicUser = this.clinic._id;
        }
        if (this.field) {
          this.searchObj.field = this.field.id;
        }
        if (this.startDate) {
          let temp = this.convertToDate(this.startDate);
          this.searchObj.startDate = moment(new Date(temp)).format(
            "YYYY-MM-DD"
          );
        }
        if (this.startfomateData) {
          let temp = this.convertToDate(this.startfomateData);
          this.searchObj.startDate = moment(new Date(temp)).format(
            "YYYY-MM-DD"
          );
        }
        if (this.endDate) {
          let temp = this.convertToDate(this.endDate);
          this.searchObj.endDate = moment(new Date(temp)).format("YYYY-MM-DD");
        }
        if (this.endformatData) {
          let temp = this.convertToDate(this.endformatData);
          this.searchObj.endDate = moment(new Date(temp)).format("YYYY-MM-DD");
        }

        this.load = true;
        const token = await localStorage.getItem("token");

        let result = await axios.post(
          process.env.VUE_APP_API_URL + `/user/get-history`,
          this.searchObj,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (result.status == 200) {
          this.load = false;
          this.historyLogs = result.data.result;
          this.total = result.data.count;
          this.endDate = moment(this.endDate).format("YYYY-MM-DD");
          this.reset();
        } else {
          this.load = false;
          this.historyLogs = [];
        }
      } catch (e) {
        //console.log(e);
        this.errorHandle(e.response);
      }
    },

    reset() {
      this.searchObj = {};
      //this.startDate = new Date();
      this.endDate = "";
      this.startDate = "";
      //this.updatedName = "";
      this.updatedUserList = [];
      //this.userName = "";
      this.userList = [];
      //this.field = "";
      //this.clinic = {};
      //this.updateClinic = {};
      this.updateUserID = "";
      //this.userID = "";
    },

    selectUser(obj) {
      this.updatedName = obj.name;
      if (obj && obj._id) {
        this.updateUserID = obj._id;
      }
      this.updatedUserList = [];
    },

    selectOwner(obj) {
      this.userName = obj.name;
      if (obj && obj._id) {
        this.userID = obj._id;
      }
      this.userList = [];
    },

    async searchUser(lbl) {
      this.fetchSearchuser(lbl, this);
    },

    fetchSearchuser: debounce(async (label, _this) => {
      if (label == "updated") {
        if (_this.updatedName.length > 3) {
          _this.searchLoad = true;
          axios
            .get(process.env.VUE_APP_API_URL + `/user/get-user`, {
              params: { q: _this.updatedName },
            })
            .then((response) => {
              if (response.data.result.length == 0) {
                _this.updatedName = "";
              }
              _this.searchLoad = false;
              _this.updatedUserList = response.data.result;
            })
            .catch(() => {
              _this.updatedUserList = [];
              _this.searchLoad = false;
            });
        }
      } else {
        if (_this.userName.length > 3) {
          _this.searchUserLoad = true;
          axios
            .get(process.env.VUE_APP_API_URL + `/user/get-user`, {
              params: { q: _this.userName },
            })
            .then((response) => {
              if (response.data.result.length == 0) {
                _this.userName = "";
              }
              _this.searchUserLoad = false;
              _this.userList = response.data.result;
            })
            .catch(() => {
              _this.userList = [];
              _this.searchUserLoad = false;
            });
        }
      }
    }, 1000),

    showDate(date) {
      return moment(date).local().format("DD/MM/YYYY");
    },

    showTime(date) {
      return moment(date).local().format("HH:mm");
    },

    onChangePage(pageNum) {
      this.current_page = pageNum;
      //this.getAllHistory();
      this.filterHistroy(this.current_page);
    },

    async getAllHistory() {
      const token = await localStorage.getItem("token");
      try {
        this.searchObj = {
          page: this.current_page,
          limit: this.limit,
          is_pagination: "true",
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        };

        this.load = true;
        let result = await axios.post(
          process.env.VUE_APP_API_URL + `/user/get-history`,
          this.searchObj,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (result.status == 200) {
          this.load = false;
          this.historyLogs = await result.data.result;
          this.total = await result.data.count;
          await this.getFieldList();
          this.getClinicList();
        } else {
          this.load = false;
          this.historyLogs = [];
        }
      } catch (e) {
        this.load = false;
        // console.log(e);
        this.errorHandle(e.response);
      }
    },

    async getClinicList() {
      try {
        this.getLoad = true;
        let result = await axios.get(
          process.env.VUE_APP_API_URL + "/clinic/getClinic",
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          }
        );
        if (result.status == 200) {
          this.getLoad = false;
          if (result && result.data && result.data.data) {
            this.clinicList = result.data.data;
          } else {
            this.getLoad = false;
            this.clinicList = [];
          }
        }
      } catch (e) {
        this.getLoad = false;
        //console.log(e);
        this.errorHandle(e.response);
      }
    },

    async getFieldList() {
      try {
        this.getLoad = true;
        const token = await localStorage.getItem("token");
        let result = await axios.get(
          process.env.VUE_APP_API_URL + "/user/get-field-for-history",
          {
            headers: { "x-access-token": token },
          }
        );
        if (result.status == 200) {
          this.getLoad = false;
          if (result && result.data && result.data.result) {
            this.fieldList = result.data.result;
            let temp = JSON.parse(JSON.stringify(this.fieldList));
            temp.forEach((element) => {
              if (element.id == "state_code") {
                element.label = "Código do estado";
              } else if (element.id == "regional_code") {
                element.label = "Código regional";
              }
            });
            this.fieldList = temp;
          } else {
            this.getLoad = false;
            this.fieldList = [];
          }
        }
      } catch (e) {
        this.getLoad = false;
        // console.log(e);
        this.errorHandle(e.response);
      }
    },
  },
  mounted() {
    this.startDate = "";
    this.endDate = "";
    let batchExpId = localStorage.getItem("batch-export");
    if (
      batchExpId === null ||
      batchExpId === "undefined" ||
      batchExpId === ""
    ) {
      this.downloadLoad = false;
    } else {
      this.downloadLoad = true;
      this.checkDownloadBatchStatus(batchExpId);
    }
    this.getAllHistory();
  },
};
</script>
<style scoped>
.list-search {
  color: black !important;
  max-height: 170px !important;
  overflow: auto !important;
  background-color: #c2c2c3 !important;
  text-align: left !important;
}
.validation-message {
  color: red;
}
.b-calendar .b-calendar-inner {
  min-width: 370px !important;
}
.history-input input {
  border-left: var(--blackcolor) solid 1px !important;
  border-radius: 5px !important;
}
</style>
