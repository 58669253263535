<template>
  <div class="reportdiv">
    <div class="filter-bg">
      <b-container>
        <b-row>
          <div class="middle-fixed">
            <div class="px-4 px-md-0">
              <div class="d-flex">
                <h3 class="tilediv">
                  <img
                    src="../assets/images/green-search-svg.svg"
                    class="searchicon"
                    alt=""
                  />
                  Pesquisa de Relatórios
                </h3>
                <span class="loader history-loader" v-if="getLoad"></span>
              </div>
              <div class="devider"></div>
              <p class="sub-title">Abaixo opção de filtros para pesquisa</p>
            </div>
            <b-form class="formdiv mt-4">
              <b-row>
                <b-col cols="6" md="3" class="mx-auto px-4 px-md-1">
                  <b-form-group label="Período de">
                    <div class="input-group">
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="startfomateData"
                          id="startDate"
                          type="text"
                          placeholder="DD-MM-YYYY"
                          autocomplete="off"
                          @blur="handleInput($event)"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="startDatePicker"
                            v-model="startDate"
                            button-only
                            right
                            locale="en-US"
                            @context="startDateContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                      <div class="d-flex flex-row">
                        <div class="validation-message" v-if="startDate_error">
                          {{ startDate_error }}
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="3" class="mx-auto px-4 px-md-1">
                  <b-form-group label="Até">
                    <div class="input-group">
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="endformatData"
                          id="endDate"
                          type="text"
                          placeholder="DD-MM-YYYY"
                          autocomplete="off"
                          @blur="handleInput($event)"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="endDatePicker"
                            v-model="endDate"
                            button-only
                            right
                            locale="en-US"
                            @context="endDateContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                      <div v-if="endDate_error" class="validation-message">
                        {{ endDate_error }}
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="mx-auto px-4 px-md-1">
                  <b-form-group class="icon-control" label="Nome do paciente">
                    <b-form-input
                      v-model="name"
                      placeholder="Nome completo"
                      ref="name"
                    ></b-form-input>
                    <b-input-group-append>
                      <img
                        src="../assets/images/pencil.svg"
                        class="searchicon"
                        alt=""
                        @click="setFocusName()"
                      />
                    </b-input-group-append>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" md="4" class="mx-auto px-4 px-md-1">
                  <b-form-group class="icon-control" label="Número do Cartão">
                    <b-form-input
                      v-model="cardNumber"
                      ref="cardNumber"
                      type="number"
                      placeholder="000000000"
                      oninput="javascript: if (this.value.length > 12) this.value = this.value.slice(0, 12);"
                    ></b-form-input>
                    <b-input-group-append>
                      <img
                        src="../assets/images/pencil.svg"
                        class="searchicon"
                        alt=""
                        @click="setFocusNumber()"
                      />
                    </b-input-group-append>
                    <label v-if="invalidError" class="error"
                      >*Por favor, digite válido Número do Cartão</label
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="4" class="mx-auto px-4 px-md-1">
                  <b-form-group class="icon-control" label="Número do CPF">
                    <b-form-input
                      v-model="cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="000.000.000-00"
                      ref="cpf"
                    ></b-form-input>
                    <b-input-group-append>
                      <img
                        src="../assets/images/pencil.svg"
                        class="searchicon"
                        alt=""
                        @click="setFocusCpf()"
                      />
                    </b-input-group-append>
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="4" class="mx-auto px-4 px-md-1 text-center">
                  <div class="position-relative">
                    <b-button
                      pill
                      variant="success"
                      class="btn-sm pesquisar"
                      @click="filterBtnClick()"
                      :disabled="getLoad"
                      ><img
                        src="../assets/images/search-icon1.svg"
                        class="searchicon me-2"
                        alt=""
                      />Pesquisar</b-button
                    >
                    <!-- <span class="loader history-loader" v-if="getLoad"></span> -->
                  </div>
                </b-col>
                <b-col cols="6" class="mx-auto px-4 px-md-1 text-center">
                  <div class="position-relative">
                    <b-button
                      pill
                      variant="success"
                      class="btn-sm pesquisar"
                      @click="reset()"
                      >Limpar</b-button
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row> </b-row>
              <!-- <b-form-group class="mb-5 mt-4">
                                                                                                                                                                                                <div class="d-flex justify-content-center">
                                                                                                                                                                                                  <div class="w-50 position-relative">
                                                                                                                                                                                                    <b-button
                                                                                                                                                                                                      pill
                                                                                                                                                                                                      variant="success"
                                                                                                                                                                                                      class="btn-sm pesquisar"
                                                                                                                                                                                                      @click="filterReport()"
                                                                                                                                                                                                      ><img
                                                                                                                                                                                                        src="../assets/images/search-icon1.svg"
                                                                                                                                                                                                        class="searchicon"
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                      />Pesquisar</b-button
                                                                                                                                                                                                    ><span class="loader history-loader" v-if="getLoad"></span>
                                                                                                                                                                                                  </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                              </b-form-group> -->
            </b-form>
          </div>
        </b-row>
      </b-container>
    </div>
    <!-- <b-container>
                                                                                                                                                                                      <h3 v-if="disableImport">Aguarde, importação de dados em andamento!</h3>
                                                                                                                                                                                
                                                                                                                                                                                      <span class="loader" v-if="downLoader"></span>
                                                                                                                                                                                    </b-container> -->
    <b-container>
      <div v-if="records.length > 0">
        <div class="middle-fixed mt-4">
          <b-row>
            <b-col cols="12" sm="8" class="mx-auto px-4 px-md-1">
              <h3 class="tilediv my-2">Resultados da pesquisa</h3>
            </b-col>
            <b-col cols="12" sm="4" class="mx-auto px-4 px-md-1 text-sm-right">
              <b-button
                pill
                variant="success"
                class="btn-md download"
                @click="downLoadData"
                :disabled="downLoader"
              >
                <img
                  src="../assets/images/download-icon-white.svg"
                  class="searchicon mx-2"
                  alt=""
                />Download</b-button
              >
            </b-col>
          </b-row>
        </div>

        <div class="table-responsive table-border mb-2 px-0">
          <table class="table striped-table">
            <thead>
              <tr>
                <th>Período</th>
                <th>Nome do Paciente</th>
                <th>Nº do CPF</th>
                <th>Data de Nascimento</th>
                <th>Telefone</th>
                <th>Número do cartão</th>
                <th>Sexo</th>
                <th>Data de Acionamento</th>
                <th>Mês do Acionamento</th>
                <th>Hora do Início do Acionamento</th>
                <th>Turno de Acionamento</th>
                <th>Dia da Semana</th>
                <th>Código do CID</th>
                <th>Descrição do CID</th>
                <th>Prescrição de Medicamento</th>
                <th>Atestado</th>
                <th>Dias de Atestado</th>
                <th>Solicitação de Exame</th>
                <th>Encaminhamento</th>
                <th>Especialidade do Encaminhamento</th>
                <th>Tipo de Usuário</th>
                <th>Perfil Etário</th>
                <th>Estado</th>
                <th>Cidade</th>
                <th>Código da Clínica</th>
                <th>Nome da Clínica</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr  v-if="records.length==0" ><td colspan="21">No Data</td></tr> -->
              <tr v-for="(item, index) in records" :key="index">
                <td>
                  {{ showDate(períodoStartDate) }} a
                  {{ showDate(períodoEndDate) }}
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.cpf }}</td>
                <td>{{ item.birthdate }}</td>
                <td>{{ item.phone_number }}</td>
                <td>{{ item.cardNumber }}</td>
                <td>{{ item.gender }}</td>
                <td>{{ item.date_acionamento }}</td>
                <td>{{ item.month }}</td>
                <td>{{ item.date_time_start }}</td>
                <td>{{ item.time_course }}</td>
                <td>{{ item.day_week }}</td>
                <td>{{ item.codigo_cid }}</td>
                <td>{{ item.cid }}</td>
                <td>{{ item.prescription }}</td>
                <td>{{ item.certificate }}</td>
                <td>{{ item.days_attested }}</td>
                <td>{{ item.exam }}</td>
                <td>{{ item.encaminhamento }}</td>
                <td>
                  {{
                    item.especialidade.length > 15
                      ? item.especialidade.substring(0, 15) + "..."
                      : item.especialidade
                  }}
                </td>
                <td>{{ item.user_type }}</td>
                <td>{{ item.age_profile }}</td>
                <td>{{ item.state }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.clinicCode }}</td>
                <td>{{ item.clinicName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="records.length && total > 5"
          class="pagination-block text-center my-1"
        >
          <paginate
            :value="current_page"
            :page-count="Math.ceil(total / limit)"
            :page-range="1"
            :click-handler="onChangePage"
            :prev-text="''"
            :next-text="''"
            :container-class="'pagination justify-content-center m-0'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
      <div
        v-if="records.length == 0 && filterRecord == true && getLoad == false"
        class="text-no-data"
      >
        Nenhum resultado de pesquisa
      </div>
    </b-container>
  </div>
</template>

<script>
import commonMixin from "../utils/commonMixin";
import axios from "axios";
const moment = require("moment");
import { notification } from "../utils/customToast";

let self;

export default {
  mixins: [commonMixin],
  data() {
    return {
      page_title: "Report - CEMIG",
      name: "",
      cardNumber: "",
      cpf: "",
      startDate: "",
      períodoStartDate: "",
      períodoEndDate: "",

      startDate_error: "",
      endDate_error: "",
      startfomateData: moment().format("DD-MM-YYYY"),
      endformatData: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
      show: true,
      getLoad: false,
      downLoader: false,
      records: [],
      current_page: 1,
      limit: 5,
      total: 0,
      disableImport: false,
      userClinic: [],
      userType: "",
      userData: {},
      invalidError: false,
      filterRecord: false,
    };
  },
  mounted() {
    self = this;
    // console.log(" mount screen" , this.userData);
    // console.log(" mount screen" , this.userID);
    if (localStorage.getItem("userObj") != null) {
      let user = JSON.parse(localStorage.getItem("userObj"));
      this.userData = user;
      if (user) {
        if (user && user.clinic) {
          this.userClinic = user.clinic;
        }
        if (user.roles.indexOf("admin") != -1) {
          this.userType = "admin";
        } else {
          this.userType = "staff";
        }
      }
    }

    this.startDate = moment().format("DD-MM-YYYY");

    let batchExpId = localStorage.getItem("batch-export-report");
    if (
      batchExpId === null ||
      batchExpId === "undefined" ||
      batchExpId === ""
    ) {
      this.downloadLoad = false;
      this.disableImport = false;
    } else {
      this.downloadLoad = true;
      this.disableImport = true;
      this.checkDownloadBatchStatus(batchExpId);
    }
  },
  methods: {
    convertToDate(date) {
      if (!date.includes("-")) {
        if (date != "") {
          let day = date.substring(0, 2);
          let month = date.substring(2, 4);
          let year = date.substring(4, 8);
          let updatedDate = year + "-" + month + "-" + day;
          return updatedDate;
        }
        return date;
      } else {
        let day = date.substring(0, 2);
        let month = date.substring(3, 5);
        let year = date.substring(6, 10);
        let updatedDate = year + "-" + month + "-" + day;
        return updatedDate;
      }
      // return date;
    },

    startDateContext(ctx) {
      if (ctx.selectedYMD) {
        if (this.endformatData != "") {
          let endDate = this.convertToDate(this.endformatData);
          if (
            new Date(ctx.selectedYMD).getTime() > new Date(endDate).getTime() &&
            new Date(ctx.selectedYMD).getTime() != new Date(endDate).getTime()
          ) {
            this.startDate_error = "Invalid Date";
          } else {
            let tempDate = ctx.selectedYMD.split("-");
            this.startfomateData =
              tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
            this.startDate_error = "";
            console.log("YOOOO", this.startfomateData);
            this.startDate = this.startfomateData;
            console.log("SET -2 ", this.startDate);
          }
        } else {
          let tempDate = ctx.selectedYMD.split("-");
          this.startfomateData =
            tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
          this.startDate = new Date(ctx.selectedYMD);
          console.log("SET -1 ", this.startDate);

          this.startDate_error = "";
        }
      }
    },
    endDateContext(ctx) {
      if (ctx.selectedYMD) {
        if (this.startfomateData != "") {
          let startDate = this.convertToDate(this.startfomateData);
          if (
            new Date(ctx.selectedYMD).getTime() <
              new Date(startDate).getTime() &&
            new Date(ctx.selectedYMD).getTime() > new Date().getTime()
          ) {
            this.endDate_error = "Invalid Date";
          } else {
            let tempDate = ctx.selectedYMD.split("-");
            this.endformatData =
              tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
            this.endDate_error = "";
          }
        } else {
          let tempDate = ctx.selectedYMD.split("-");
          this.endformatData =
            tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
          this.endDate_error = "";
        }
      }
    },
    handleInput(e) {
      let dateId = e.target.id;
      let newDateMoment = moment(this.convertToDate(e.target.value)).format(
        "YYYY-MM-DD"
      );
      newDateMoment = new Date(newDateMoment);
      if (moment(this.convertToDate(e.target.value)).isValid()) {
        let currentDay = new Date();
        currentDay = new Date(currentDay);
        if (newDateMoment.getTime() > currentDay.getTime()) {
          if (dateId === "startDate") {
            this.startDate_error = "por favor insira um comprimento válido";
          }
          if (dateId === "endDate") {
            this.endDate_error = "por favor insira um comprimento válido";
          }
        } else {
          if (e.target.value.length < 8) {
            if (dateId === "startDate") {
              this.startDate_error = "por favor insira um comprimento válido";
            }
            if (dateId === "endDate") {
              this.endDate_error = "por favor insira um comprimento válido";
            }
          } else {
            let updatedDate = this.convertToDate(e.target.value);
            console.log(updatedDate, dateId);

            if (dateId === "startDate") {
              this.startDate = moment(updatedDate).format("DD-MM-YYYY");
              this.startfomateData = moment(updatedDate).format("DD-MM-YYYY");
              this.startDate_error = "";
            }
            if (dateId === "endDate") {
              if (moment(updatedDate).format("DD-MM-YYYY") < this.startDate) {
                this.endDate_error = "data de término inválida";
              } else {
                this.endDate = moment(updatedDate).format("DD-MM-YYYY");
                this.endformatData = moment(updatedDate).format("DD-MM-YYYY");
                this.endDate_error = "";
              }
            }
          }
        }
      } else {
        if (dateId === "startDate") {
          this.startDate = null
          // this.startDate_error = "data de término inválida";
        }
        if (dateId === "endDate") {
          this.endDate = null
          // this.endDate_error = "por favor insira um comprimento válido";
        }
      }
    },
    async downLoadData() {
      try {
        let obj = {
          user: this.userData.objectId,
        };
        if (this.cpf) {
          this.cpf = this.cpf.toString();
          const patientCPF = this.cpf.toString();
          this.cpf = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
          if (this.cpf.length < 11) {
            for (let j = 0; j < 11 - patientCPF.length; j++) {
              this.cpf = "0" + this.cpf;
            }
          }
          obj.cpf = this.cpf;
        }
        if (this.userType != "admin") {
          obj.owner_clinic = this.userClinic;
        }
        if (this.name) {
          obj.name = this.name.trim();
        }
        if (this.cardNumber) {
          obj.cardNumber = this.cardNumber.toString();
        }

        // if (this.startDate) {
        //   obj.startDate = moment(this.startDate).format("DD-MM-YYYY");
        // }
        // if (this.endDate) {
        //   obj.endDate = moment(this.endDate).format("DD-MM-YYYY");
        // }
        if (this.startDate) {
          let temp = this.convertToDate(this.startDate);
          obj.startDate = temp// moment(new Date(temp)).format("YYYY-MM-DD");
        }
        if (this.startfomateData) {
          let temp = this.convertToDate(this.startfomateData);
          console.log(
            "🚀 ~ file: Report.vue ~ line 565 ~ downLoadData ~ temp",
            temp
          );
          obj.startDate = temp// moment(new Date(temp)).format("YYYY-MM-DD");
        }
        if (this.endDate) {
          let temp = this.convertToDate(this.endDate);
          console.log(
            "🚀 ~ file: Report.vue ~ line 571 ~ downLoadData ~ temp",
            temp
          );
          obj.endDate = temp// moment(new Date(temp)).format("YYYY-MM-DD");
        }
        if (this.endformatData) {
          let temp = this.convertToDate(this.endformatData);
          obj.endDate = temp // moment(new Date(temp)).format("YYYY-MM-DD");
        }
        console.log("obj", obj);
        this.downLoader = true;
        this.disableImport = true;
        const token = await localStorage.getItem("token");

        let result = await axios.post(
          process.env.VUE_APP_API_URL + "/report/download-report",
          obj,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (result.status == 200) {
          this.downLoader = false;
          if (result && result.data && result.data.batch) {
            let batchno = result.data.batch;

            notification("Download de dados em andamento!", "success");

            localStorage.setItem("batch-export-report", batchno);
            setTimeout(() => {
              this.checkDownloadBatchStatus(batchno);
            }, 10000);
          } else {
            this.downLoader = false;
            this.disableImport = false;
          }
        } else {
          return;
        }
      } catch (e) {
        console.log(e);
        this.downLoader = false;
        this.disableImport = false;
        this.errorHandle(e.response);
      }
    },
    async updateStatus(batch) {
      console.log("dfewdwd");
      try {
        const token = await localStorage.getItem("token");
        console.log(token,"dfewdwd");
        this.getLoad = true;
        let result = await axios.get(
          process.env.VUE_APP_API_URL +
            `/report/update-complete-report-status/${batch}`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        console.log(result);
        if (result.status == 200) {
          this.getLoad = false;
          this.$emit("getfiltes");
          // this.reset();
        } else {
          this.getLoad = false;
        }
      } catch (e) {
        this.getLoad = false;
        this.errorHandle(e.response);
        console.log(e);
      }
    },
    async checkDownloadBatchStatus(batchId) {
      try {
        const token = await localStorage.getItem("token");
        let results = await axios.get(
          process.env.VUE_APP_API_URL +
            `/report/get-download-report-status/${batchId}`,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (results.status === 200) {
          if (results.data.payload == "completed") {
            localStorage.removeItem("batch-export-report");
            this.downloadLoad = false;
            this.disableImport = false;
            var fileURL = results.data.link;
            console.log(fileURL);
            window.open(fileURL, "_blank");
            this.updateStatus(batchId);

            this.$emit("getfiltes");
            // // var fileLink = document.createElement("a");

            // fileLink.href = fileURL;
            // document.body.appendChild(fileLink);

            // fileLink.click();
            notification("Download do arquivo com sucesso!", "success");
          } else if (results.data.payload == "failed") {
            localStorage.removeItem("batch-export-report");
            this.downloadLoad = false;
            this.disableImport = false;
            this.failedReason = results.data.reason;
          } else if (results.data.payload == "no data") {
            localStorage.removeItem("batch-export-report");
            this.downloadLoad = false;
            this.disableImport = false;
          } else {
            setTimeout(() => {
              this.checkDownloadBatchStatus(batchId);
            }, 10000);
          }
        }
      } catch (e) {
        this.errorHandle(e.response);
      }
    },
    showDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    onChangePage(pageNum) {
      this.current_page = pageNum;
      this.filterReport();
    },

    filterBtnClick() {
      this.current_page = 1;
      if (!isNaN(this.cardNumber || this.cardNumber.length <= 12)) {
        this.invalidError = false;
        this.filterReport();
      } else {
        this.invalidError = true;
      }
    },

    setFocusName() {
      this.$refs.name.$el.focus();
    },

    setFocusNumber() {
      this.$refs.cardNumber.$el.focus();
    },

    setFocusCpf() {
      this.$refs.cpf.$el.focus();
    },

    async filterReport() {
      try {
        console.log("HMMMM == ", self.startDate);

        let obj = {
          page: this.current_page,
          limit: this.limit,
          is_pagination: "true",
        };
        if (this.userType != "admin") {
          obj.owner_clinic = this.userClinic;
        }
        this.filterRecord = true;
        if (this.cpf) {
          this.cpf = this.cpf.toString();
          const patientCPF = this.cpf.toString();
          this.cpf = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
          if (this.cpf.length < 11) {
            for (let j = 0; j < 11 - patientCPF.length; j++) {
              this.cpf = "0" + this.cpf;
            }
          }
          obj.cpf = this.cpf;
        }
        if (this.name) {
          obj.name = this.name.trim();
        }
        if (this.cardNumber) {
          obj.cardNumber = this.cardNumber.toString();
        }
        if (this.startDate) {
          let temp = this.convertToDate(this.startDate);
          console.log("🚀 ~ file: Report.vue ~ line 764 ~ filterReport ~ temp", temp)
          obj.startDate = temp;// moment(new Date(temp)).tz("America/Sao_Paulo").format("YYYY-MM-DD");
          console.log("🚀 ~ file: Report.vue ~ line 766 ~ filterReport ~ obj.startDate", obj.startDate)
        }
        if (this.startfomateData) {
          let temp = this.convertToDate(this.startfomateData);
          console.log("🚀 ~ file: Report.vue ~ line 770 ~ filterReport ~ temp", temp)
          obj.startDate = temp;// moment(new Date(temp)).format("YYYY-MM-DD");
          console.log("🚀 ~  ~ obj.startDate", obj.startDate)
        }

        this.períodoStartDate = obj.startDate;
        if (this.endDate) {
          let temp = this.convertToDate(this.endDate);
          obj.endDate = temp // moment(new Date(temp).time).format("YYYY-MM-DD");
        }
        if (this.endformatData) {
          let temp = this.convertToDate(this.endformatData);
          obj.endDate = temp // moment(new Date(temp)).format("YYYY-MM-DD");
        }
        this.períodoEndDate = obj.endDate;
        this.getLoad = true;
        const token = await localStorage.getItem("token");

        let result = await axios.post(
          process.env.VUE_APP_API_URL + `/report/generate-report`,
          obj,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (result.status == 200) {
          this.getLoad = false;
          this.records = result.data.data;
          this.total = result.data.count;
          // this.reset();
        } else {
          this.getLoad = false;
          this.records = [];
        }
      } catch (e) {
        this.getLoad = false;
        this.errorHandle(e.response);
        console.log(e);
      }
    },
    reset() {
      console.log("RESET");
      this.startDate = new Date();
      this.endDate = new Date();
      this.name = "";
      this.cpf = "";
      this.cardNumber = "";
      this.page = 1;
    },
  },
};
</script>

<style scoped>
th,
td {
  min-width: 100px !important;
}

.text-no-data {
  min-height: 451px;
  line-height: 451px;
}

.error {
  color: red;
}

.validation-message {
  color: red;
}
</style>
