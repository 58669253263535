<template>
  <div class="resetpassword">
    <!-- <img src="../assets/images/left-arrow.svg" alt="" class="leftarrow" /> -->
    <template>
      <b-container>
        <b-row>
          <b-col
            md="5"
            class="d-none d-lg-flex align-items-center justify-content-end"
          >
            <img
              src="../assets/images/password-img.svg"
              class="passwordimg"
              alt=""
            />
          </b-col>
          <b-col md="7" class="mx-auto">
            <div class="mainbox-form">
              <div class="topheading mb-4">
                <h2 class="heading">Defina sua nova senha</h2>
              </div>
              <b-form v-if="show">
                <b-form-group id="input-group-2">
                  <b-form-input
                    :type="type"
                    placeholder=" "
                    id="password"
                    v-model="password"
                  ></b-form-input>
                  <label class="subtext">Senha</label>
                  <template v-if="type == 'password'">
                    <img
                      src="../assets/images/eye.svg"
                      class="showpassword"
                      alt="password"
                      @click="showPassword"
                    />
                  </template>
                  <template v-if="type != 'password'">
                    <img
                      src="../assets/images/eye-hide.svg"
                      class="showpassword"
                      alt="password"
                      @click="showPassword"
                    />
                  </template>
                </b-form-group>

                <b-progress :value="value" :max="max" class="mb-3"></b-progress>

                <ul class="select-selection">
                  <li
                    v-bind:style="[
                      isAlphaNumerci ? { 'font-weight': 'bold' } : {},
                    ]"
                  >
                    <span
                      class="check"
                      v-bind:style="[
                        isAlphaNumerci
                          ? {
                              'border-bottom': '2px solid #4ca344',
                              'border-right': '2px solid #4ca344',
                            }
                          : {
                              'border-bottom': '2px solid var(--lightcolor1)',
                              'border-right': '2px solid var(--lightcolor1)',
                            },
                      ]"
                    ></span>
                    Letras e números
                  </li>
                  <li
                    v-bind:style="[isCapital ? { 'font-weight': 'bold' } : {}]"
                  >
                    <span
                      class="check"
                      v-bind:style="[
                        isCapital
                          ? {
                              'border-bottom': '2px solid #4ca344',
                              'border-right': '2px solid #4ca344',
                            }
                          : {
                              'border-bottom': '2px solid var(--lightcolor1)',
                              'border-right': '2px solid var(--lightcolor1)',
                            },
                      ]"
                    ></span>
                    Letras maiúsculas
                  </li>
                  <li v-bind:style="[isLower ? { 'font-weight': 'bold' } : {}]">
                    <span
                      class="check"
                      v-bind:style="[
                        isLower
                          ? {
                              'border-bottom': '2px solid #4ca344',
                              'border-right': '2px solid #4ca344',
                            }
                          : {},
                      ]"
                    ></span>
                    Letras minúsculas
                  </li>
                  <li
                    v-bind:style="[isLength ? { 'font-weight': 'bold' } : {}]"
                  >
                    <span
                      class="check"
                      v-bind:style="[
                        isLength
                          ? {
                              'border-bottom': '2px solid #4ca344',
                              'border-right': '2px solid #4ca344',
                            }
                          : {
                              'border-bottom': '2px solid var(--lightcolor1)',
                              'border-right': '2px solid var(--lightcolor1)',
                            },
                      ]"
                    ></span>
                    10 caracteres
                  </li>
                  <li
                    v-bind:style="[isSpecial ? { 'font-weight': 'bold' } : {}]"
                  >
                    <span
                      class="check"
                      v-bind:style="[
                        isSpecial
                          ? {
                              'border-bottom': '2px solid #4ca344',
                              'border-right': '2px solid #4ca344',
                            }
                          : {
                              'border-bottom': '2px solid var(--lightcolor1)',
                              'border-right': '2px solid var(--lightcolor1)',
                            },
                      ]"
                    ></span>
                    Caracter especial
                  </li>
                </ul>

                <b-button
                  pill
                  block
                  class="w-100 intrarbutton"
                  type="button"
                  variant="primary"
                  :disabled="!isValid"
                  @click="changePassword()"
                  >Continuar</b-button
                >
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import commonMixin from "../utils/commonMixin";
import { notification as notificationToast } from "../utils/customToast.js";
var self;
export default Vue.extend({
  name: "ResetPassword",
  mixins: [commonMixin],
  data() {
    return {
      page_title: "Reset Password - CEMIG",
      password: "",
      value: 0,
      max: 100,
      show: true,
      showScreen: true,
      isValid: false,
      isAlphaNumerci: false,
      isAlphaNumApply: false,
      isCapital: false,
      isCapitalApply: false,
      isLower: false,
      isLowerApply: false,
      isLength: false,
      isLengthApply: false,
      isSpecial: false,
      isSpecialApply: false,
      type: "password",
      loadVal: 0,
    };
  },
  mounted() {
    self = this;
    console.log(this.$route.query.token);
    if (this.$route.query.token) {
      console.log("TEST");
      localStorage.setItem("token", this.$route.query.token);
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
  },
  watch: {
    password: function (password) {
      this.isValid = this.checkPassword(password);
      this.isCapital = this.isUpper(password);
    },
  },
  methods: {
    checkPassword: function (inputtxt) {
      let passwAlphNumeric = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
      let passwSpecial = /[&^$#`*_+~.()/'/"!\-:@]/;
      // let passwFinal = /(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{10,}/;
      let passwFinal = /(?=.*[0-9])(?=.*[!.@#$%^&*])[a-zA-Z0-9!.@#$%^&*]{10,}/;

      this.isAlphaNumerci = passwAlphNumeric.test(inputtxt);
      if (this.isAlphaNumerci && !this.isAlphaNumApply) {
        this.value += 20;
        this.isAlphaNumApply = true;
      }
      if (!this.isAlphaNumerci && this.isAlphaNumApply) {
        this.value -= 20;
        this.isAlphaNumApply = false;
      }

      this.isCapital = this.isUpper(inputtxt);
      if (this.isCapital && !this.isCapitalApply) {
        this.value += 20;
        this.isCapitalApply = true;
      }
      if (!this.isCapital && this.isCapitalApply) {
        this.value -= 20;
        this.isCapitalApply = false;
      }

      this.isLower = this.isLoweCase(inputtxt);
      if (this.isLower && !this.isLowerApply) {
        this.value += 20;
        this.isLowerApply = true;
      }
      if (!this.isLower && this.isLowerApply) {
        this.value -= 20;
        this.isLowerApply = false;
      }
      if (inputtxt.length > 9 && !this.isLengthApply) {
        this.value += 20;
        this.isLength = true;
        this.isLengthApply = true;
      }
      if (inputtxt.length < 10 && this.isLengthApply) {
        this.value -= 20;
        this.isLength = false;
        this.isLengthApply = false;
      }

      this.isSpecial = passwSpecial.test(inputtxt);
      if (this.isSpecial && !this.isSpecialApply) {
        this.value += 20;
        this.isSpecialApply = true;
      }
      if (!this.isSpecial && this.isSpecialApply) {
        this.value -= 20;
        this.isSpecialApply = false;
      }

      if (inputtxt.length == 0) {
        this.value = 0;
      }

      if (passwFinal.test(inputtxt)) {
        if (
          this.isCapital &&
          this.isLower &&
          this.isSpecial &&
          this.isAlphaNumerci
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    isLoweCase: function (str) {
      return /[a-z]/.test(str);
    },
    isUpper: function (str) {
      return /[A-Z]/.test(str);
    },

    todo: function () {
      this.intervalid1 = setInterval(
        function () {
          if (this.loadVal == 100) {
            this.loadVal = 0;
          }
          this.loadVal += 10;
        }.bind(this),
        300
      );
    },
    changePassword() {
      console.log("dfeewr");
      this.showScreen = false;
      let token = localStorage.getItem("token");
      //
      // this.loadVal

      this.todo();
      axios
        .post(
          process.env.VUE_APP_API_URL + "/resetpassword",
          {
            password: this.password,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          console.log("RES");
          if (response.status == 200) {
            localStorage.clear();
            notificationToast("success", "warning");
            self.$router.push("/login");
          } else {
            notificationToast("Invalid Token", "error");
            self.$router.push("/login");
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            notificationToast(
              "Seu link de redefinição de senha expirou.",
              "error"
            );
             self.$router.push("/login");
          } else {
              notificationToast(error.response.data.message,'error')
          }
        });
    },
    //
    hasNumber: function (myString) {
      return /\d/.test(myString);
    },
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    buttonClick: function () {
      // this.$router
      this.$router.push("/home");
    },
  },
});
</script>
