global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

require('jquery-toast-plugin')

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSweetalert2 from 'vue-sweetalert2'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";
// import VueToast from "vue-toast-notification";
import VueMask from 'v-mask'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/src/scss/vue-select.scss";
import 'sweetalert2/dist/sweetalert2.min.css';

import Zendesk from '@dansmaculotte/vue-zendesk';

// require('apminsight')({ 
//   licenseKey : 'us_75fc8e3395757199705d5f87f110f5b9',
//   appName : process.env.APP_NAME,
//   port : 80});
  

// import "vue-toast-notification/dist/theme-sugar.css";
window.Vue = Vue;

const moment = require('moment')
require('moment/locale/pt-br')
 
Vue.use(require('vue-moment'), {
    moment
})
import "./assets/base.scss";
Vue.use(VueMask);

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// Or as a directive-only
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.use(Zendesk, {
  key: '921173e8-98cf-42a6-b65b-488f84ec73e3',
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      offset: { horizontal: '30px', vertical : '17px' },
      color: {
            theme: '#026447',
            launcher: '#026447', // This will also update the badge
            launcherText: '#fff',
            button: '#026447',
            resultLists: '#fff',
            header: '#026447',
            articleLinks: '#fff'
         },
      departments: {
            enabled: ['Dashboard Iron'],
            select: 'Dashboard Iron',
      }
      // chat: {
        
      // }
   }
  }
})


// Or only as a filter-only
import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter);

Vue.component("v-select", vSelect);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2)


Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
